import React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/Info';

import i18n from "@helpers/i18n";
import { customersTableHeightPerTotalPages } from "@helpers/muiTable";

import ContentBox from "@components/ContentBox/ContentBox";
import searchIcon from "@assets/img/search_icon.svg";

import useCustomersTable from "./useCustomersTable";
import CustomTableFooter from "../CustomTableFooter";
import CustomerModal from "../CustomerModal/CustomerModal";
import CustomerMenu from "./CustomerMenu/CustomerMenu";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    width: 450,
    maxWidth: 450,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}));

const CustomersTable = props => {
  const {
    isMobileOrTablet,
    partner,
    advisor,
    showHeader,
    copyList,
    page,
    setPage,
    rowsPerPage,
    selectedClient,
    setRowsPerPage,
    handleChangePage,
    requestSearch,
    handleClientClick,
    handleClientModalClose,
    updateCustomers,
    caseFilterContext,
    nextStepOptions,
    setCustomerState
  } = useCustomersTable(props);

  return (
    <ContentBox>
      {showHeader && 
        <h2>
          {i18n("dashboard_selector", "customers")}
        </h2>
      }
      <Box sx={{ width: "100%", mt: {xs: 0, md: 2} }}>
        <TextField
          size="small"
          fullWidth
          placeholder="Sök på namn, person- eller organisationsnummer"
          type="search"
          onInput={e => requestSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  component="img"
                  sx={{
                    height: 20,
                    width: 20,
                    paddingBottom: 0.25
                  }}
                  alt="logo"
                  src={searchIcon}
                />
              </InputAdornment>
            ),
            sx: {
              px: 3,
              fontSize: "13px",
              borderRadius: "20px",
              width: "100%",
            }
          }}
          sx={{ display:"flex", alignItems: "center"}}
        />
        <TableContainer component={Box} sx={{ overflowY: "hidden"}}>
          <Box
            sx={{
              display: "block",
              width: "100%",
              minHeight: customersTableHeightPerTotalPages[rowsPerPage] || "unset",
              border: 0,
              overflow: "auto"
            }}
          >
            <Table
              aria-label="custom pagination table"
              sx={{ 
                minWidth: 700,
                mt: 1,
                border: 0
              }}
            >
              <TableHead sx={{ 
                borderBottom: "1px solid #000",
              }}>
                <TableRow sx={{ "& th": { borderBottom: 0 } }}>
                  <TableCell 
                    sx={{
                      width: "2.5%",
                      p: 1,
                      pb: 0
                    }}>
                    <Typography variant="tableCellHeader"> </Typography>
                  </TableCell>
                  {!isMobileOrTablet &&
                    <TableCell 
                      sx={{
                        width: "2.5%",
                        p: 1,
                        pb: 0
                      }}>
                      <Typography variant="tableCellHeader"> </Typography>
                    </TableCell>
                  }
                  <TableCell 
                    sx={{
                      width: "40%",
                      p: 1,
                      pb: 0
                    }}>
                    <Typography variant="tableCellHeader">Kund</Typography>
                  </TableCell>
                  <TableCell 
                    sx={{
                      width: "15%",
                      p: 1,
                      pb: 0
                    }}>
                    <Typography variant="tableCellHeader" >
                      Person-/Org.nummer
                    </Typography>
                  </TableCell>
                  <TableCell 
                    sx={{
                      width: "40%",
                      p: 1,
                      pb: 0
                    }}>
                    <Typography variant="tableCellHeader" >
                      E-post
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? copyList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : copyList
                )
                  // (copyList.length > 0 ? copyList : rows)
                  .map((client, key) => (
                    <TableRow key={key}>
                      <TableCell
                        sx={{
                          py: 0.5,
                          borderBottom: "none"
                        }}
                      >
                        <CustomerMenu
                          advisor={advisor}
                          client={client}
                          partner={partner}
                          nextStepOptions={nextStepOptions}
                          setCustomerState={setCustomerState}
                          cobForm={caseFilterContext.cobForm}
                        />
                      </TableCell>
                      {!isMobileOrTablet &&
                        <TableCell
                          sx={{
                            py: 0.5,
                            borderBottom: "none"
                          }}
                        >
                          <HtmlTooltip
                            disableFocusListener
                            placement="left"
                            title={
                              <Box sx={{
                                width: "100%",
                                display: "flex",
                                padding: 1.5,
                                alignItems: "flex-start",
                              }}>
                                <Box sx={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "flex-start",
                                  borderBottom: "1px solid #000",
                                }}>
                                  <Typography color="inherit" variant="h5">
                                    {client.name}
                                  </Typography>
                                </Box>
                                <Box sx={{
                                  width: "100%",
                                  mt: 2, 
                                  display: "flex",
                                  alignItems: "flex-start"
                                }}>
                                  <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    mt: 0.6
                                  }}>
                                    <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
                                      <b>Person- lorg.nummer</b>
                                    </Typography>                                
                                    <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
                                      {client.id}
                                    </Typography>
                                  </Box>
                                  <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    mt: 0.6
                                  }}>
                                    <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
                                      <b>Epost</b>
                                    </Typography>                                
                                    <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
                                      {client.email}
                                    </Typography>
                                  </Box>
                                  <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    mt: 0.6
                                  }}>
                                    <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
                                      <b>Telefon</b>
                                    </Typography>                                
                                    <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
                                      {client.phone}
                                    </Typography>
                                  </Box>
                                  <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    mt: 0.6
                                  }}>
                                    <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
                                      <b>Adress</b>
                                    </Typography>                                
                                    <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
                                      {client.street}
                                    </Typography>
                                  </Box>
                                  <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    mt: 0.6
                                  }}>
                                    <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
                                      <b>KYC-Datum</b>
                                    </Typography>                                
                                    <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
                                      {client.kyc_date}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            }
                          >
                            <InfoIcon sx={{mt: 0.45, fontSize: "18px"}} />
                          </HtmlTooltip>
                        </TableCell>
                      }
                      <TableCell
                        sx={{
                          px: 1.2,
                          py: 0.5,
                          borderBottom: "none",
                          color: "secondary.lighter",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                      >
                        <Link
                          href="#"
                          onClick={handleClientClick(client)}
                        >
                          <Typography variant="tableCell">
                            {client.name}
                            </Typography>
                        </Link>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          px: 1.2, 
                          py: 0.5,
                          borderBottom: "none",
                          color: "inherit" 
                        }}
                      >
                        <Typography
                          variant="tableCell"
                          color="secondary.lighter"
                        >
                          {client.id}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          px: 1.2,
                          py: 0.5,
                          borderBottom: "none",
                          color: "inherit",                        
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                      >
                        <Typography
                          variant="tableCell"
                          color="secondary.lighter"
                        >
                          {client.email}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                {copyList.length < 1 && (
                  <TableRow>
                    <TableCell sx={{ border: 0 }}>Inga resultat.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
          <CustomTableFooter
            type="customers"
            data={copyList}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleChangePage={handleChangePage}
          />
        </TableContainer>
      </Box>
      {selectedClient && (
        <CustomerModal 
          visible={true}
          partner={partner}
          advisor={advisor}
          client={selectedClient}
          onClose={handleClientModalClose}
          updateCustomers={updateCustomers}
          caseFilterContext={caseFilterContext}
        />
      )}
    </ContentBox>
  );
};

export default CustomersTable;
