import React, {Fragment} from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";

import { getComparator, tableHeightPerTotalPages } from "@helpers/muiTable";
import i18n from "@helpers/i18n";

import ContentBox from "@components/ContentBox/ContentBox";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import CustomTableFooter from "@cobComponents/CustomTableFooter";
import searchIcon from "@assets/img/search_icon.svg";
import TableExpanderIcon from "@components/TableExpanderIcon";

import usePoaTable from "./usePoaTable";
import DashboardTabs from "../DashboardTabs";
import EnhancedTableHead from "./EnhancedTableHead";

const PoaTable = props => {
  const {
    loaded,
    tabs,
    columns,
    copyList,
    numCases,
    page,
    setPage,
    searchString,
    setSearchString,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    handleRequestSort,
    requestSearch,
    handleEntryClick,    
    onAction,
    caseFilter,
    setCaseFilter
  } = usePoaTable(props);

  return (
    <ContentBox
      style={{
        display: "inline",
        maxHeight: "70vh",
        boxShadow: "none",
        margin: 0,
        padding: 0
      }}
    >
      <Box 
        sx={{
          width: "100%",
          alignItems: "flex-start"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {xs: "column", md: "row"},
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}
        >
          <DashboardTabs 
            type="poa" 
            translationGroup="dashboard_poas"
            tabs={tabs}
            fetchingData={!loaded}
            setPage={setPage}
            setSearchString={setSearchString}
            caseFilter={caseFilter}
            setCaseFilter={setCaseFilter}
          />
          <TextField
            size="small"
            placeholder="Sök..."
            type="search"
            onInput={e => requestSearch(e.target.value)}
            InputProps={{
              value: searchString,
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      paddingBottom: 0.25
                    }}
                    alt="logo"
                    src={searchIcon}
                  />
                </InputAdornment>
              ),
              sx: {
                px: 3,
                fontSize: "13px",
                borderRadius: "20px",
                width: "100%",
              }
            }}
            sx={{ 
              width: {xs: "100%", md: "30%"},
            }}
          />
        </Box>
        <TableContainer component={Box} sx={{ overflowY: "hidden"}}>
          <Box
            sx={{
              display: "block",
              width: "100%",
              minHeight: tableHeightPerTotalPages[rowsPerPage] || "unset",
              border: 0,
              overflow: "auto",
              overflowY: "hidden"
            }}
          >
            <Table aria-label="Poa Table"
              className="mui-table"
              sx={{
                minWidth: 700,
                mt: 1,
                border: 0,
                tableLayout: "fixed"
              }}
            >
              <EnhancedTableHead
                columns={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
              {!loaded ? (
                  <TableRow
                    sx={{
                      height: "100%",
                      "&:last-child td": { border: 0 }
                    }}
                  >
                    <TableCell colSpan={columns.length}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: tableHeightPerTotalPages[rowsPerPage]*0.7
                        }}
                      >
                        <ActivityIndicator color="#4cb7d5"  busy/>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <Fragment>
                    {copyList && copyList.sort(getComparator(order, orderBy))
                      .map((entry, key) => (
                        <Fragment key={key}>
                          <TableRow
                            className={entry.state}
                            sx={{
                              height: 40,
                              cursor: "pointer",
                              "&:last-child td": { border: 0 }
                            }}
                            onClick={() => handleEntryClick(entry.poa_id)}
                          >
                            <TableCell 
                              sx={{
                                px: 0,
                                pl: {xs: 0.5, md: 1},
                                py: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <TableExpanderIcon toggled={entry?.toggled}/>
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 0,
                                py: 0.5,
                                borderBottom: "none",
                                color: "secondary.lighter",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}
                            >
                              <Typography variant="tableCell">
                                {entry.advisorName}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 0,
                                py: 0.5,
                                borderBottom: "none",
                                color: "secondary.lighter"
                              }}
                            >
                              <Typography variant="tableCell">
                                {entry.ssn}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 0,
                                py: 0.5,
                                borderBottom: "none",
                                color: "secondary.lighter",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}
                            >
                              <Typography variant="tableCell">
                                {entry.name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 0,
                                py: 0.5,
                                borderBottom: "none",
                                color: "secondary.lighter"
                              }}
                            >
                              <Typography variant="tableCell">
                                {entry.created}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 0,
                                py: 0.5,
                                borderBottom: "none",
                                color: "secondary.lighter"
                              }}
                            >
                              <Typography variant="tableCell">
                                {entry.signed}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 0,
                                py: 0.5,
                                borderBottom: "none",
                                color: "secondary.lighter",
                              }}
                            >
                              <Typography variant="tableCell">
                                {entry.valid_to}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 0,
                                pr: 1,
                                py: 0.5,
                                borderBottom: "none",
                                color: "secondary.lighter"
                              }}
                            >
                              <Typography variant="tableCell">
                                {entry.invalidated}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          {entry?.toggled && (
                            <TableRow>
                              <TableCell
                                colSpan={8}
                                sx={{
                                  pl: "2.5%"
                                }}
                              >
                                {entry.state === "sent" && (
                                  <Button onClick={onAction.bind(this, "remind", entry.poa_id, entry.personal_number)}>
                                    {i18n("table_container", "send_reminder")}
                                  </Button>
                                )}

                                {entry.state === "signed" && (
                                  <Button onClick={onAction.bind(this, "revoke", entry.poa_id)}>
                                    {i18n("table_container", "revoke")}
                                  </Button>
                                )}

                                <h3 style={{fontSize: "13px"}}>{i18n("table_container_poas", "response_insurance")}</h3>
                                {entry.companies.map((c, key) => {
                                  return (
                                    <React.Fragment key={key}>
                                      <div style={{
                                        alignItems: "flex-start",
                                        padding: "10px 0",
                                        margin: "0 0 2px 0",
                                        borderTop: "1px solid #DDD",
                                        flexDirection: "row"
                                      }}>
                                        <strong>{c.company}</strong>
                                        <span style={{flex: "1", display: "block", textAlign: "right"}}>
                                          {c.is_customer === null && (
                                            i18n("table_container_poas", "sent")
                                          )}

                                          {c.is_customer === '0' && (
                                            i18n("table_container_poas", "not_customer")
                                          )}

                                          {c.is_customer === '1' && (
                                            i18n("table_container_poas", "customer")
                                          )}
                                        </span>
                                      </div>
                                    </React.Fragment>
                                  )
                                })}
                              </TableCell>
                            </TableRow>
                          )}                  
                        </Fragment>
                      ))}
                  </Fragment>
                )}
              </TableBody>
            </Table>
          </Box>
          <CustomTableFooter
            loaded={loaded}
            type="poa"
            totalCases={numCases}
            data={copyList}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage === -1 && numCases > 100 ? 100 : rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleChangePage={handleChangePage}
          />
        </TableContainer>
      </Box>
    </ContentBox>
  );
};

export default PoaTable;