import React, {Fragment} from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";

import { getComparator, tableHeightPerTotalPages } from "@helpers/muiTable";
import i18n from "@helpers/i18n";

import ContentBox from "@components/ContentBox/ContentBox";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import CustomTableFooter from "@cobComponents/CustomTableFooter";
import searchIcon from "@assets/img/search_icon.svg";
import TableExpanderIcon from "@components/TableExpanderIcon";

import useFuturTable from "./useFuturTable";
import DashboardTabs from "../DashboardTabs";
import EnhancedTableHead from "./EnhancedTableHead";

const FuturTable = props => {
  const {
    user,
    whitelabel,
    loaded,
    tabs,
    columns,
    copyList,
    numCases,
    page,
    setPage,
    searchString,
    setSearchString,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    handleRequestSort,
    requestSearch,
    handleEntryClick,    
    onAction,
    caseFilter,
    setCaseFilter
  } = useFuturTable(props);

  return (
    <ContentBox
      style={{
        display: "inline",
        maxHeight: "70vh",
        boxShadow: "none",
        margin: 0,
        padding: 0
      }}
    >
      <Box 
        sx={{
          width: "100%",
          alignItems: "flex-start"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {xs: "column", md: "row"},
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}
        >
          <DashboardTabs 
            type="futur" 
            translationGroup="dashboard_futur"
            tabs={tabs}
            fetchingData={!loaded}
            setPage={setPage}
            setSearchString={setSearchString}
            caseFilter={caseFilter}
            setCaseFilter={setCaseFilter}
          />
          <TextField
            size="small"
            placeholder="Sök..."
            type="search"
            onInput={e => requestSearch(e.target.value)}
            InputProps={{
              value: searchString,
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      paddingBottom: 0.25
                    }}
                    alt="logo"
                    src={searchIcon}
                  />
                </InputAdornment>
              ),
              sx: {
                px: 3,
                fontSize: "13px",
                borderRadius: "20px",
                width: "100%",
              }
            }}
            sx={{ 
              width: {xs: "100%", md: "30%"},
            }}
          />
        </Box>
        <TableContainer component={Box} sx={{ overflowY: "hidden"}}>
          <Box
            sx={{
              display: "block",
              width: "100%",
              minHeight: tableHeightPerTotalPages[rowsPerPage] || "unset",
              border: 0,
              overflow: "auto",
              overflowY: "hidden"
            }}
          >
            <Table 
              className="mui-table"
              aria-label="Futur Table"
              sx={{
                minWidth: 700,
                mt: 1,
                border: 0,
                tableLayout: "fixed"
              }}
            >
              <EnhancedTableHead
                columns={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {!loaded ? (
                  <TableRow
                    sx={{
                      height: "100%",
                      "&:last-child td": { border: 0 }
                    }}
                  >
                    <TableCell colSpan={columns.length}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: tableHeightPerTotalPages[rowsPerPage]*0.7
                        }}
                      >
                        <ActivityIndicator color="#4cb7d5"  busy/>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <Fragment>
                    {copyList && copyList.sort(getComparator(order, orderBy))
                      .map((entry, key) => (
                        <Fragment key={key}>
                          <TableRow
                            className={entry.state}
                            sx={{
                              height: 40,
                              cursor: entry.state === "draft" || entry.state === "sent" || entry.state === "signed"
                                ? "pointer"
                                : "auto",
                              "&:last-child td": { border: 0 }
                            }}
                            onClick={() =>
                              (entry.state === "draft" || entry.state === "sent" || entry.state === "signed") &&
                              handleEntryClick(entry.caseId)
                            }
                          >
                            <TableCell 
                              sx={{
                                px: 0,
                                pl: {xs: 0.5, md: 1},
                                py: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              {(entry.state === "draft" || entry.state === "sent" || entry.state === "signed") && (
                                <TableExpanderIcon toggled={entry?.toggled}/>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 0,
                                py: 0.5,
                                borderBottom: "none",
                                color: "secondary.lighter",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}
                            >
                              <Typography variant="tableCell">
                                {entry.advisorName}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 0,
                                py: 0.5,
                                borderBottom: "none",
                                color: "secondary.lighter"
                              }}
                            >
                              <Typography variant="tableCell">
                                {entry.ssn}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 0,
                                py: 0.5,
                                borderBottom: "none",
                                color: "secondary.lighter",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}
                            >
                              <Typography variant="tableCell">
                                {entry.name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 0,
                                py: 0.5,
                                borderBottom: "none",
                                color: "secondary.lighter",
                              }}
                            >
                              <Typography variant="tableCell">
                                {entry.created}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 0,
                                pr: 1,
                                py: 0.5,
                                borderBottom: "none",
                                color: "secondary.lighter",
                                textAlign: "right"
                              }}
                            >
                              <Typography variant="tableCell">
                                {entry.sign_status_doc}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          {entry?.toggled && (
                            <TableRow>
                              <TableCell
                                colSpan={6}
                                sx={{
                                  pl: "2.5%"
                                }}
                              >
                                {entry.state === "draft" && (
                                  <React.Fragment>
                                    <Button onClick={onAction.bind(this, "continue", entry.caseId, entry.ssn)}>
                                      {i18n("table_container", "continue_consultation")}
                                    </Button>
                                    {user.role !== "assistant" && (
                                      <Button onClick={onAction.bind(this, "delete", entry.caseId, entry.ssn)}>
                                        {i18n("table_container", "remove_draft")}
                                      </Button>
                                    )}
                                  </React.Fragment>
                                )}

                                {(entry.state === "sent" || entry.downloadable_meeting) && (
                                  <Button onClick={onAction.bind(this, "copy", entry.caseId, entry.ssn)}>
                                    {i18n("table_container", "copy_consultation")}
                                  </Button>
                                )}

                                {whitelabel === "fair" && entry.state === "sent" && !entry.downloadable_meeting && (
                                  <React.Fragment>
                                    <Button onClick={onAction.bind(this, "remind_meeting", entry.caseId, entry.ssn)}>
                                                    {i18n("table_container_futur", "send_meeting_attachment_reminder")}
                                    </Button>
                                  </React.Fragment>
                                )}

                                {whitelabel === "fair" && entry.state === "sent" && !entry.downloadable_account && (
                                  <React.Fragment>
                                    <Button onClick={onAction.bind(this, "remind", entry.caseId, entry.ssn)}>
                                                    {i18n("table_container_futur", "send_futur_application_reminder")}
                                    </Button>
                                  </React.Fragment>
                                )}

                                {whitelabel !== "fair" && entry.state === "sent" && !entry.downloadable_account && (
                                  <React.Fragment>
                                    <Button onClick={onAction.bind(this, "remind", entry.caseId, entry.ssn)}>
                                                    {i18n("table_container_futur", "send_reminder")}
                                    </Button>
                                  </React.Fragment>
                                )}

                                {entry.downloadable_meeting && (
                                  <Button onClick={onAction.bind(this, "download_meeting", entry.caseId, entry.ssn)}>
                                                {i18n("table_container_futur", "download_assently_meeting_attachment")} (PDF)
                                  </Button>
                                )}

                                {entry.downloadable_account && (
                                  <Button onClick={onAction.bind(this, "download_futur", entry.caseId, entry.ssn)}>
                                              {i18n("table_container_futur", "download_futur_application")} (PDF)
                                  </Button>
                                )}

                                {entry.signatories.length > 0 && (
                                  <React.Fragment>
                                    <h4 style={{marginBottom: "15px"}}>{i18n("table_container", "signatories")}: Mötesbilaga</h4>
                                    <ul className={"case-signatories"}>
                                      {entry.signatories.map((party, key) => {
                                        const rowSigned = entry.state === "signed" || party.Signed;
                                        return (
                                          <li key={key} className={rowSigned ? "signed" : ""}>
                                            <span className={"box"}>
                                              {rowSigned && (
                                                <React.Fragment>
                                                  &#10003;
                                                </React.Fragment>
                                              )}
                                            </span>

                                            <span className={"name"}>
                                              {party.Name}
                                            </span>

                                            {party.Signed && (
                                              <span className={"date"}>
                                                                  ({i18n("table_container", "signed")}: {party.Signed})
                                              </span>
                                            )}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </React.Fragment>
                                )}

                                {entry.signatories_futur.length > 0 && (
                                  <React.Fragment>
                                    <h4 style={{marginBottom: "15px"}}>{i18n("table_container", "signatories")}: Futur</h4>
                                    <ul className={"case-signatories"}>
                                      {entry.signatories_futur.map(party => {
                                        const rowSigned = entry.FuturStatus === "processed" || party.Signed;
                                        return (
                                          <li className={rowSigned ? "signed" : ""} key={entry.caseId}>
                                            <span className={"box"}>
                                              {rowSigned && (
                                                <React.Fragment>
                                                  &#10003;
                                                </React.Fragment>
                                              )}
                                            </span>

                                            <span className={"name"}>
                                              {party.Name}
                                            </span>

                                            {party.Signed && (
                                              <span className={"date"}>
                                                                  ({i18n("table_container", "signed")}: {party.Signed})
                                              </span>
                                            )}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </React.Fragment>
                                )}
                              </TableCell>
                            </TableRow>
                          )}                  
                        </Fragment>
                      ))}
                  </Fragment>
              )}
              </TableBody>
            </Table>
          </Box>
          <CustomTableFooter
            loaded={loaded}
            type="futur"
            totalCases={numCases}
            data={copyList}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage === -1 && numCases > 100 ? 100 : rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleChangePage={handleChangePage}
          />
        </TableContainer>
      </Box>
    </ContentBox>
  );
};

export default FuturTable;