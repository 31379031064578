import React from "react";
import ContentBox from "@components/ContentBox/ContentBox";
import {createB64Context, createClassName, stringifyQueryParams} from "@helpers/utils";
import i18n from "@helpers/i18n";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import api from "@helpers/api";
import { getCookie } from "@helpers/cookies";
import Modal from "@components/Modal/Modal";
import Settings from "@classes/Settings";
import FuturTable from "./FuturTable";

/**
 * Futurkonton
 */
export default class DashboardFutur extends React.PureComponent {

	state = _getInitialState.call(this);

	async componentDidMount() {
		this._isMounted = true;
		const userCaseList = await _getUserFuturCaseList.call(this);
		this.setState({
			isLoading: false,
			userCaseList: userCaseList.batch,
      totalCases: parseInt(userCaseList.total)
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

  async componentDidUpdate(prevProps, prevState)
	{
    if (this.props.caseFilter.futur !== prevProps.caseFilter.futur) {
      this.setState({
        isLoadingCases: true
      });
      const userCaseList = await _getUserFuturCaseList.call(this);
      this.setState({
        isLoadingCases: false,
        userCaseList: userCaseList.batch,
        totalCases: parseInt(userCaseList.total)
      });
    }
	}

	render() {
		const {props, state} = this;
		const {user, partner, showHeader} = props;

		// /**
		//  * Filter caseList depending on which tab has been selected (if the selected tab is not "all")
		//  */
    // const caseList = props.caseFilter.futur === "all"
    // ? state.userCaseList
    // : state.userCaseList.filter(userCase => userCase.state === props.caseFilter.futur &&
    // (
    //     (props.type === 'customerModal' && props?.client?.id === userCase.ssn) ||
    //     (props.type !== 'customerModal')
    // ));

		return (
      <>
				<ContentBox
         style={
          props.type === "customerModal" ? 
            { 
              maxHeight: "70vh",
              margin: 0,
              padding: 0,
              overflow: "auto",
              boxShadow: "none"
            }
          :
            {}
          }
        >
          {showHeader && (
            <h2>
              {i18n("dashboard_selector", "futur")}
            </h2>
          )}
					{state.isLoading && (
						<div style={{padding: "91px 0"}}>
							<ActivityIndicator color="#4cb7d5" busy/>
						</div>
					)}

					{!state.isLoading && (
            <>
              <div
                className="dashboard-cases"
                style={
                  props.type === "customerModal" ? 
                    { 
                      minHeight: "unset"
                    }
                  :
                    {}
                }
              >
                <FuturTable
                  data={state.userCaseList}
                  totalCases={state.totalCases}
                  isLoadingCases={state.isLoadingCases || false}
                  whitelabel={partner.name}
                  user={user}
                  caseFilter={props.caseFilter} 
                  setCaseFilter={props.setCaseFilter}
                  onAction={this._onRowAction}
                  getCases={_getUserFuturCaseList.bind(this)}
                />
                {state.isDisabled && (
                  <div className="dashboard-cases-disabled">
                    <ActivityIndicator color="#4cb7d5" busy/>
                  </div>
                )}
              </div>
            </>
					)}
				</ContentBox>

				<Modal visible={state.isReminderModalVisible}>
					<p>En påminnelse om signering har skickats till kunden</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideReminderModal}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isDeleteModalVisible}>
					<p>{i18n("dashboard_sessions", "draft_removed")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideDeleteModal}/>
					</Modal.ButtonsContainer>
				</Modal>
      </>
		);
	}

	_onRowAction = async (action, caseId, ssn) =>
	{
		const {user, advisor, partner} = this.props;

		if (action === 'delete')
		{
			api("/case/delete", {
				caseId: caseId, userId: user.ssn, partnerId: advisor.ssn
			});

			const newCaseList = [];
			this.state.userCaseList.forEach(function (userCase)
			{
				if (userCase.caseId !== caseId)
				{
					newCaseList.push(userCase);
				}
			});

			this.setState({
				isDeleteModalVisible: true, userCaseList: newCaseList
			});

			return;
		}

		if (action === 'remind')
		{
			api("/futur/reminder", {
				caseId: caseId
			});

			this.setState({
				isReminderModalVisible: true
			});

			return;
		}

		if (action === 'remind_meeting')
		{
			api("/case/reminder", {
				caseId: caseId
			});

			this.setState({
				isReminderModalVisible: true
			});

			return;
		}

		if (action === 'download_meeting')
		{
			const rawDataInBase64 = await api("/case/downloadReceipt", {
				caseId: caseId, userId: user.ssn, partnerId: advisor.ssn
			}, {}, true);

			if (rawDataInBase64 !== "")
			{
				this.downloadFile(caseId + ".pdf", rawDataInBase64);
			}

			return;
		}

		if (action === 'download_futur')
		{
			const rawDataInBase64 = await api("/futur/download", {
				caseId: caseId, userId: user.ssn, partnerId: advisor.ssn
			}, {}, true);

			if (rawDataInBase64 !== "")
			{
				this.downloadFile(caseId + ".pdf", rawDataInBase64);
			}

			return;
		}

		if (action === 'copy')
		{
			const createdCaseId = await api("/case/copy", {
				caseId: caseId, userId: user.ssn, partnerId: advisor.ssn
			}, {}, true);

			this._continueConsultation(createdCaseId.result, partner, ssn, user);
		}

		if (action === 'continue')
		{
			this._continueConsultation(caseId, partner, ssn, user);
		}
	};

	_continueConsultation = (caseId, partner, ssn, user) => {
    if (this.props.type === "customerModal") {      
      document.documentElement.style.overflow = "auto"; // re-enable main page scroll
    }
    
		if (partner.futurcase)
		{
			const encodedContext = createB64Context(partner.partnerId + "client", user, ssn, caseId);
			window.location.href = partner.futurcase + stringifyQueryParams({
				context: encodedContext,
				token: Settings.get("AUTH_TOKEN")
			});
		}
	}

	_onHideReminderModal = () => {
		this.setState({
			isReminderModalVisible: false
		});
	};

	_onHideDeleteModal = () =>
	{
		this.setState({
			isDeleteModalVisible: false
		});
	};

	downloadFile(filename, base64pdf) {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		//blob = atob(blob);

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const link = document.createElement('a');
		link.href = "data:application/pdf;base64," + base64pdf;
		link.download = filename;
		link.click();
	}
}

function _getInitialState() {
	return {
		tabSelected: "all",
		isLoading: true,
    isLoadingCases: false,
		isDisabled: false,
		isMounted: false,
		isReminderModalVisible: false,
		isRecallModalVisible: false,
		isDeleteModalVisible: false,
		userCaseList: []
	};
}

function _getUserFuturCaseList(page, searchString, order, orderBy) {
	const ssn = this.props.user.ssn;

  // check if we have any options saved..
  const cobOptions = JSON.parse(getCookie("cobOptions")) || null;
  // .. and set rowsPerPage
  const rowsPerPage= cobOptions?.rowsPerPage["futur"] || 10;

	return new Promise(async (resolve, reject) => {
		try {
			console.log("Loading list of Futur cases...");
			const cases = await api("/futur/list", {
				ssn: ssn,
        filter: this.props.caseFilter.futur,
        page: page || 0,
        order: order || "desc",
        orderBy: orderBy || "created",
        searchString: searchString || "",
        limit: rowsPerPage === -1 ? 100 : rowsPerPage
			});

			if (this._isMounted) {
				resolve(cases);
			}
		}
		catch (e) {
			console.error(e.message);
			reject();
		}
	});
}