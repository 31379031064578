import React from "react";
import ContentBox from "@components/ContentBox/ContentBox";
import i18n from "@helpers/i18n";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import api from "@helpers/api";
import { getCookie } from "@helpers/cookies";
import Modal from "@components/Modal/Modal";
import PoaTable from "./PoaTable";

/**
 * Futurkonton
 */
export default class DashboardPoas extends React.PureComponent
{

	state = _getInitialState.call(this);

	async componentDidMount()
	{
		this._isMounted = true;
		const userCaseList = await _getUserPoaCaseList.call(this);
		this.setState({
			isLoading: false,
			userCaseList: userCaseList.batch,
      totalCases: parseInt(userCaseList.total)
		});
	}

	componentWillUnmount()
	{
		this._isMounted = false;
	}

  async componentDidUpdate(prevProps, prevState)
	{
    if (this.props.caseFilter.poa !== prevProps.caseFilter.poa) {
      this.setState({
        isLoadingCases: true
      });
      const userCaseList = await _getUserPoaCaseList.call(this);
      this.setState({
        isLoadingCases: false,
        userCaseList: userCaseList.batch,
        totalCases: parseInt(userCaseList.total)
      });
    }
	}

	render()
	{
		const {props, state} = this;
		const {faAuth, showHeader} = props;

		// /**
		//  * Filter caseList depending on which tab has been selected (if the selected tab is not "all")
		//  */

    // const caseList = props.caseFilter.poa === "all"
    // ? state.userCaseList
    // : state.userCaseList.filter(userCase => userCase.state === props.caseFilter.poa &&
    // (
    //     (props.type === 'customerModal' && props?.client?.id === userCase.ssn) ||
    //     (props.type !== 'customerModal')
    // ));


		return (
			<>
				<ContentBox
         style={
          props.type === "customerModal" ? 
            { 
              maxHeight: "70vh",
              margin: 0,
              padding: 0,
              overflow: "auto",
              boxShadow: "none"
            }
          :
            {}
          }
        >
          {showHeader && (
            <h2>
              {i18n("dashboard_selector", "poa")}
            </h2>
          )}
					{state.isLoading && (
						<div style={{padding: "91px 0"}}>
							<ActivityIndicator color="#4cb7d5" busy/>
						</div>
					)}


					{!state.isLoading && (
            <>
              <div
                className="dashboard-cases"
                style={
                  props.type === "customerModal" ? 
                    { 
                      minHeight: "unset"
                    }
                  :
                    {}
                }
              >
                <PoaTable
                  data={state.userCaseList}
                  totalCases={state.totalCases}
                  isLoadingCases={state.isLoadingCases || false}
                  faAuth={faAuth}
                  caseFilter={props.caseFilter} 
                  setCaseFilter={props.setCaseFilter}
                  getCases={_getUserPoaCaseList.bind(this)}
                  onAction={this._onRowAction}
                />

                {state.isDisabled && (
                  <div className="dashboard-cases-disabled">
                    <ActivityIndicator color="#4cb7d5" busy/>
                  </div>
                )}
              </div>
            </>
					)}
				</ContentBox>

				<Modal visible={state.isReminderModalVisible}>
					<p>{i18n("dashboard_poas", "reminder_sent")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideReminderModal}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isRecallModalVisible}>
					<p>{i18n("dashboard_poas", "case_revoked")}.</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideRecallModal}/>
					</Modal.ButtonsContainer>
				</Modal>
			</>
		);
	}

	_onRowAction = (action, poaId, customerSsn) =>
	{    
		const {type, advisor} = this.props;
    
		if (action === 'remind')
		{
			api("/fmk/remind", {
				ssn: customerSsn,
				advisor: advisor.ssn
			});

			this.setState({
				isReminderModalVisible: true
			});
		}
		else if (action === 'revoke')
		{
			api("/fmk/revoke", {
				poaId: poaId,
				advisor: advisor.ssn
			});

			this.state.userCaseList.forEach(function (userCase)
			{
				if (userCase.poa_id === poaId)
				{
					userCase.state = 'recalled';
				}
			});

			this.setState({
				isRecallModalVisible: true,
				userCaseList: this.state.userCaseList
			});
		}

    if (type === "customerModal") {
      document.documentElement.style.overflow = "auto"; // re-enable main page scroll
    }
	};

	_onHideReminderModal = () =>
	{
		this.setState({
			isReminderModalVisible: false
		});
	};

	_onHideRecallModal = () =>
	{
		this.setState({
			isRecallModalVisible: false
		});
	};
}

function _getInitialState()
{
	return {
		tabSelected: "all",
		isLoading: true,
    isLoadingCases: false,
		isDisabled: false,
		isMounted: false,
		isReminderModalVisible: false,
		isRecallModalVisible: false,
		userCaseList: []
	};
}

function _getUserPoaCaseList(page, searchString, order, orderBy)
{
	const ssn = this.props.user.ssn;
  
  // check if we have any options saved..
  const cobOptions = JSON.parse(getCookie("cobOptions")) || null;
  // .. and set rowsPerPage
  const rowsPerPage= cobOptions?.rowsPerPage["sessions"] || 10;

	return new Promise(async (resolve, reject) =>
	{
		try
		{
			console.log("Loading list of poas...");
			const cases = await api("/fmk/list", {
				ssn: ssn,
        filter: this.props.caseFilter.poa,
        page: page || 0,
        order: order || "desc",
        orderBy: orderBy || "created",
        searchString: searchString || "",
        limit: rowsPerPage === -1 ? 100 : rowsPerPage
			});

			if (this._isMounted)
			{
				resolve(cases);
			}
		}
		catch (e)
		{
			console.error(e.message);
			reject();
		}
	});
}