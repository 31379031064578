import React from "react";

// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import FileDrop from "@components/FileDrop/FileDrop";

// HELPERS
import {createClassName, localeNumber, decodeBase64Document} from "@helpers/utils";

// OTHER
import fulfillment from "./View14.fulfillment";


export default class CobView14 extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state)
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) callbackFn();
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
          onClick: () => {
            if (this.state.isFulfilled === true) {
              return;
            }
            
            this.setState({hasFormValidationErrors: true});
            return setAppState({
              showValidationWarning: true
            });
          }
        }
			}
		});
	}

	render() {
		const {state} = this;
		const {customer} = this.props;

		const totalDocsSize = state.docs.reduceRight((a, b) => a + Math.round(b.size / 1024), 0);
		const maxUploadFileSize = Math.round(state.maxUploadFileSize / 1024);
		const classes = createClassName("Cob-view Cob-view14", {
			"size-exceeded": totalDocsSize > maxUploadFileSize,
		});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Övriga dokument</h2>

					<div className="Cob-view14-description">
						<p>Här lägger ni till övriga dokument som ska till kund och/eller Fair.</p>
						<p><em>Obs! Filnamn måste vara unika.</em></p>
					</div>

					{state.requiresFactsheets && (
						<React.Fragment>
							<div style={{
								textAlign: "left",
								display: "block",
								margin: "0 0 20px 0",
								padding: "15px",
								background: "#FFFFD5",
								borderRadius: "3px",
								maxWidth: "500px",
								lineHeight: "21px"
							}}>
								<strong
									style={{
										display: "block",
										margin: "0 0 3px 0"
									}}>Notera</strong>
								Rådgivningen inkluderar avsnitt om investeringsrådgivning. Var god bifoga faktablad för produkt som
								rekommenderats.
							</div>
						</React.Fragment>
					)}

					{state.isLoneGuardian && (
						<React.Fragment>
							<div style={{
								textAlign: "left",
								display: "block",
								margin: "0 0 20px 0",
								padding: "20px",
								background: "#FFFFD5",
								borderRadius: "3px",
								maxWidth: "500px"
							}}>
								<strong
									style={{
										display: "block",
										margin: "0 0 5px 0"
									}}>Notera</strong>
								Rådgivningen gäller omyndig person med ensam vårdnadshavare. Var god bifoga intyg om
								ensam vårdnad nedan.
							</div>
						</React.Fragment>
					)}

					{customer.isCompany && (
						<React.Fragment>
							<div style={{
								textAlign: "left",
								display: "block",
								margin: "0 0 20px 0",
								padding: "15px",
								background: "#F7F7F7",
								borderRadius: "3px",
								maxWidth: "500px",
								lineHeight: "21px"
							}}>
								<strong
									style={{
										display: "block",
										margin: "0 0 3px 0"
									}}>Notera</strong>
								Rådgivningen gäller företag, glöm därför inte att bifoga
								reg. bevis samt vidimerad ID-kopia på verkliga huvudmän.
							</div>
						</React.Fragment>
					)}

					<div className="Cob-view14-fields">
						<DocsList
							items={state.docs}
							totalSize={totalDocsSize}
							maxSize={maxUploadFileSize}
							onDocRemove={this._onDocRemove}
						/>

						<FileDrop
              filterKey={"other-documents"}
							dropText="Dra och släpp PDF:er här"
							busyText="Laddar in dokument..."
							accept="application/pdf"
							onDrop={this._onDocDrop}
						/>
					</div>
				</ContentBox>
			</div>
		);
	}

	// Internal methods
	_onDocDrop = (documents) => {
		const {props, state} = this;
		const {customer} = props;
		const docs = [...state.docs];
		const docNames = [...state.docNames];

		for (let i = 0, n = documents.length; i < n; i++) {
			const doc = documents[i];

			// Prevent duplicates
			if (docNames.indexOf(doc.name) !== -1) continue;

			docs.push({name: doc.name, size: doc.size});
			docNames.push(doc.name);

			customer.addDocument({
				name: doc.name,
				size: doc.size,
				data: doc.data, // doc.data.substr(0, 100),
				filterKey: doc.filterKey
			});
		}

		this.setState({docs, docNames, isFulfilled: this.isFulfilled({docs, docNames})}, () => {
			props.setAppState({viewFulfilled: this.state.isFulfilled});
		});
	};

	_onDocRemove = (item) =>
	{
		const {props, state} = this;
		const {customer} = props;

		const docs = [...state.docs].filter((d) =>
		{
			return d.name !== item.name && d.document_name !== item.name;
		});

		const docNames = [...state.docNames].filter((d) =>
		{
			return d !== item.name && d !== item.document_name;
		});

		customer.removeDocumentWithName(item.name);
		this.setState({docs, docNames});
	}
}


// PRIVATE COMPONENTS
class DocsList extends React.PureComponent {

	render() {
		const {props} = this;
		const {items, totalSize, maxSize, onDocRemove} = props;
		const classes = createClassName("Cob-view14-DocsList", {});

		return (
			<div className={classes}>
				<div className="Cob-view14-DocsList-info">
					<div className="total-docs">
						<strong>Antal dokument:</strong>
						<span>{items.length}</span>
					</div>
					<div className="max-size">
						<strong>Maximal uppladdningsstorlek:</strong>
						<span>{`${ localeNumber(totalSize) } KB / ${ localeNumber(maxSize) } KB`}</span>
					</div>
				</div>

				<div className="Cob-view14-DocsList-items">
					{items.length === 0 ? (
						<div className="Cob-view14-DocsList-empty">Inga dokument har lagts till.</div>
					) : items.map((item, index) => (
						<DocsListItem key={index} item={item} index={index} onRemove={onDocRemove}/>
					))}
				</div>
			</div>
		);
	}
}

class DocsListItem extends React.PureComponent {

	render() {
		const {item, onRemove} = this.props;
		const size = Math.round(item.size * 100) / 100000;

		return (
			<div className="Cob-view14-DocsList-Item">
				<div className="left">
					<div className="name">{item.name}</div>
					<div className="size">{localeNumber(size)} KB</div>
				</div>
				<div className="remove-button material-icons"
					 onClick={onRemove ? this._onRemove : undefined}>remove_circle
				</div>
			</div>
		);
	}

	// Internal methods
	_onRemove = () => {
		const {index, onRemove} = this.props;
		onRemove(index);
	}
}


// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, viewNo, hasFormValidationErrors} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));   
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer);  
	newState.isFulfilled = this.isFulfilled(newState);  
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	const newState = {
		docs: customer.documents.map(doc => {
      // get document size from raw_data if property exists
      const decodedDoc = decodeBase64Document(doc);
      return {name: doc.name || doc.document_name, size: doc.size|| decodedDoc.length}
    }),
		docNames: customer.documents.map(doc => doc.name|| doc.document_name),
		maxUploadFileSize: customer.maxUploadFileSize,
		customerState: {},
	};

	// Check if the user selected Investeringsrådgivning, in which case
	const adviceProductsAmount = customer.getData("allocation_amount_advice") || "";
	newState.requiresFactsheets = adviceProductsAmount.length > 0 && !(
		customer.getData("checkbox_allocation_advice_is_fund") === 'Ja' &&
		customer.getData("checkbox_account_isk") === 'Ja'
	);

	const loneGuardianName = customer.getData("caretaker_name_1") || "";
	newState.isLoneGuardian = !customer.isCompany && loneGuardianName.length > 0;

	newState.isFulfilled = false;

	return newState;
}

function _getFields() {
	return [];
}