import React, {Fragment} from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";

import { getComparator, tableHeightPerTotalPages } from "@helpers/muiTable";
import i18n from "@helpers/i18n";

import ContentBox from "@components/ContentBox/ContentBox";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import TextView from "@components/TextView/TextView";
import CustomTableFooter from "@cobComponents/CustomTableFooter";
import searchIcon from "@assets/img/search_icon.svg";
import TableExpanderIcon from "@components/TableExpanderIcon";

import useOtherTable from "./useOtherTable";
import DashboardTabs from "../DashboardTabs";
import EnhancedTableHead from "./EnhancedTableHead";

const OtherTable = props => {
  const {
    user,
    type,
    partner,
    onMessage,
    onShareOpen,
    loaded,
    tabs,
    columns,
    copyList,
    numCases,
    page,
    setPage,
    searchString,
    setSearchString,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    handleRequestSort,
    requestSearch,
    handleEntryClick,    
    onAction,
    caseFilter,
    setCaseFilter
  } = useOtherTable(props);

  return (
    <ContentBox
      style={{
        display: "inline",
        maxHeight: "70vh",
        boxShadow: "none",
        margin: 0,
        padding: 0
      }}
    >
      <Box 
        sx={{
          width: "100%",
          alignItems: "flex-start"
        }}
      >          
        <Box
          sx={{
            display: "flex",
            flexDirection: {xs: "column", md: "row"},
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}
        >
          <DashboardTabs 
            type="other" 
            translationGroup="dashboard_sessions"
            tabs={tabs}
            fetchingData={!loaded}
            setPage={setPage}
            setSearchString={setSearchString}
            caseFilter={caseFilter}
            setCaseFilter={setCaseFilter}
          />
          <TextField
            size="small"
            placeholder="Sök..."
            type="search"
            onInput={e => requestSearch(e.target.value)}
            InputProps={{
              value: searchString,
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      paddingBottom: 0.25
                    }}
                    alt="logo"
                    src={searchIcon}
                  />
                </InputAdornment>
              ),
              sx: {
                px: 3,
                fontSize: "13px",
                borderRadius: "20px",
                width: "100%",
              }
            }}
            sx={{ 
              width: {xs: "100%", md: "30%"},
            }}
          />
        </Box>
        <TableContainer component={Box} sx={{ overflowY: "hidden"}}>
          <Box
            sx={{
              display: "block",
              width: "100%",
              minHeight: tableHeightPerTotalPages[rowsPerPage] || "unset",
              border: 0,
              overflow: "auto",
              overflowY: "hidden"
            }}
          >
            <Table aria-label="Other Table"
              className="mui-table"
              sx={{
                minWidth: 700,
                mt: 1,
                border: 0,
                tableLayout: "fixed"
              }}>
              <EnhancedTableHead
                columns={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {!loaded ? (
                  <TableRow
                    sx={{
                      height: "100%",
                      "&:last-child td": { border: 0 }
                    }}
                  >
                    <TableCell colSpan={columns.length}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: tableHeightPerTotalPages[rowsPerPage]*0.7
                        }}
                      >
                        <ActivityIndicator color="#4cb7d5"  busy/>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <Fragment>
                    {copyList && copyList.sort(getComparator(order, orderBy))
                      .map((entry, key) => {
                        const msgTitleRef = React.createRef();
                        const msgTextRef = React.createRef();

                        const isExpandable = 
                          (type === "main" && entry.state === "draft" && (user.hasPermission("case.create") || user.ssn === entry.ssn.replaceAll("-", ""))) ||
                          (entry.state === "sent" || entry.state === "signed" || entry.state === "recalled") ||
                          (type === "main" && entry.state === "processing" && user.hasPermission("case.change_state")) ||
                          (user.role !== "assistant" && !entry.is_shared_case && onShareOpen !== undefined) ||
                          ((entry.state === "processing" || entry.state === "sent") && (user.hasPermission("case.msgs_write") || entry.messages.length > 0)) ||
                          entry?.signatories?.length > 0 ||
                          entry.commission_sharing !== "" ||
                          (entry.message_to_bo !== null && entry.message_to_bo !== "") ||
                          entry.audit.length > 0 && user.hasPermission("case.audit_log");
                                      
                        return (
                          <Fragment key={key}>
                            <TableRow
                              className={entry.state}
                              sx={{
                                height: 40,
                                cursor: isExpandable ? "pointer" : "default",
                                "&:last-child td": { border: 0 }
                              }}
                              onClick={() =>
                                isExpandable && handleEntryClick(entry.caseId)
                              }
                            >
                              <TableCell 
                                sx={{
                                  width: "2.5%",
                                  pl: {xs: 0.5, md: 1},
                                  pr: 0,
                                  py: 0.5,
                                  borderBottom: "none",
                                }}
                              >
                                {isExpandable && (                                
                                  <TableExpanderIcon toggled={entry?.toggled}/>
                                )}
                              </TableCell>
                              <TableCell
                                sx={{
                                  px: 0,
                                  py: 0.5,
                                  borderBottom: "none",
                                  color: "secondary.lighter"
                                }}
                              >
                                <Typography variant="tableCell">
                                  {entry.type}
                                </Typography>
                              </TableCell>
                              {user.role !== "advisor" && (
                                <TableCell
                                  sx={{
                                    px: 0,
                                    py: 0.5,
                                    borderBottom: "none",
                                    color: "secondary.lighter",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}
                                >
                                  <Typography variant="tableCell">
                                    {entry.advisorName}
                                  </Typography>
                                </TableCell>
                              )}
                              <TableCell
                                sx={{
                                  px: 0,
                                  py: 0.5,
                                  borderBottom: "none",
                                  color: "secondary.lighter",
                                }}
                              >
                                <Typography variant="tableCell">
                                  {entry.ssn}
                                </Typography>
                              </TableCell>
                              <TableCell
                                  sx={{
                                    px: 0,
                                    py: 0.5,
                                    borderBottom: "none",
                                    color: "secondary.lighter",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}
                                >
                                  <Typography variant="tableCell">
                                    {entry.name}
                                    {entry.workflow === 'futur' && (
                                      <em style={{ display: "block", color: "#95a4ac" }}>Depåöpping inför tecknande av KF</em>
                                    )}

                                    {entry.is_shared_case && (
                                      <em style={{ display: "block", color: "#95a4ac" }}>Huvudrådgivare: {entry.advisorName} </em>
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: user.role !== "advisor" ? "12.5%" : "15%",
                                    px: 0,
                                    py: 0.5,
                                    borderBottom: "none",
                                    color: "secondary.lighter",
                                  }}
                                >
                                  <Typography variant="tableCell">
                                    {entry.changed}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: user.role !== "advisor" ? "12.5%" : "15%",
                                    px: 0,
                                    py: 0.5,
                                    borderBottom: "none",
                                    color: "secondary.lighter",
                                  }}
                                >
                                  <Typography variant="tableCell">
                                    {entry.sent}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: user.role !== "advisor" ? "10%" : "12.5%",
                                    px: 0,
                                    pr: 1,
                                    py: 0.5,
                                    borderBottom: "none",
                                    color: "secondary.lighter",
                                    textAlign: "right"
                                  }}
                                >
                                  <Typography variant="tableCell">
                                    {entry.signed}
                                  </Typography>
                                </TableCell>
                            </TableRow>
                            {entry?.toggled && (
                              <TableRow>
                                <TableCell
                                  colSpan={user.role !== "advisor" ? 8 : 7}
                                  sx={{
                                    pl: "2.5%"
                                  }}
                                >
                                  {type === "main" && entry.state === "draft" && (user.hasPermission("case.create") || user.ssn === entry.ssn.replaceAll("-", "")) && (
                                    <React.Fragment>
                                      <Button onClick={onAction.bind(this, "continue", entry.caseId, entry.ssn)}>
                                        {i18n("table_container", "continue_consultation")}
                                      </Button>
                                      {user.role !== "assistant" && !entry.is_shared_case && (
                                        <Button onClick={onAction.bind(this, "delete", entry.caseId, entry.ssn)}>
                                          {i18n("table_container", "remove_draft")}
                                        </Button>
                                      )}
                                    </React.Fragment>
                                  )}

                                  {(entry.state === "sent" || entry.state === "signed" || entry.state === "recalled") && (
                                    <React.Fragment>
                                      {type === "main" && (user.hasPermission("case.create") || user.ssn === entry.ssn.replaceAll("-", "")) && (
                                        <Button onClick={onAction.bind(this, "copy", entry.caseId, entry.ssn)}>
                                          {i18n("table_container", "copy_consultation")}
                                        </Button>
                                      )}

                                      {entry.state === "sent" && (
                                        <Button onClick={onAction.bind(this, "remind", entry.caseId, entry.ssn)}>
                                          {i18n("table_container", "send_reminder")}
                                        </Button>
                                      )}

                                      {user.role !== "assistant" && entry.state === "sent" && (
                                        <Button onClick={onAction.bind(this, "recall", entry.caseId, entry.ssn)}>
                                          {i18n("table_container", "revoke")}
                                        </Button>
                                      )}

                                      {type === "main" && entry.state === "sent" && user.hasPermission("case.change_state") && (
                                        <Button onClick={onAction.bind(this, "processing", entry.caseId, entry.ssn)}>
                                          {i18n("table_container", "in_progress")}
                                        </Button>
                                      )}

                                      {entry.state === "signed" && (user.hasPermission("case.download") || user.ssn === entry.ssn.replaceAll("-", "")) && (
                                        <Button onClick={onAction.bind(this, "download", entry.caseId, entry.ssn)}>
                                          {i18n("table_container", "download_documentation")} (PDF)
                                        </Button>
                                      )}
                                    </React.Fragment>
                                  )}

                                  {type === "main" && entry.state === "processing" && user.hasPermission("case.change_state") && (
                                    <React.Fragment>
                                      <Button onClick={onAction.bind(this, "unprocessing", entry.caseId, entry.ssn)}>
                                        {i18n("table_container", "reset_to_sent")}
                                      </Button>

                                      <Button onClick={onAction.bind(this, "recall", entry.caseId, entry.ssn)}>
                                        {i18n("table_container", "revoke")}
                                      </Button>
                                    </React.Fragment>
                                  )}

                                  {user.role !== "assistant" && !entry.is_shared_case && onShareOpen !== undefined && (
                                    <Button onClick={onShareOpen.bind(this, entry.caseId)}>
                                      {i18n("table_container", "sharing_action")}
                                    </Button>
                                  )}

                                  {(entry.state === "processing" || entry.state === "sent") && (
                                    <React.Fragment>
                                      {(user.hasPermission("case.msgs_write") || entry.messages.length > 0) && (
                                        <h4 style={{marginBottom: "15px"}}>
                                          {i18n("table_container", "messages_from_backoffice")}
                                        </h4>
                                      )}

                                      {entry.messages.length > 0 && (
                                        <ul className={"messages"}>
                                          {entry.messages.map((msg, key) =>
                                          {
                                            return (
                                              <li key={key}>
                                                <div>
                                                  <span className="msg-created">
                                                    {msg.Created}
                                                  </span>
                                                  <span className="msg-title">
                                                    {msg.Subject}
                                                  </span>
                                                </div>
                                                <p>
                                                  {msg.Message}

                                                  <span className={"msg-originator"}>
                                                    - {msg.OriginatorName}
                                                  </span>
                                                </p>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      )}

                                      {entry.messages.length === 0 && (
                                        <p>
                                          <em>{i18n("table_container", "no_case_messages")}</em>
                                        </p>
                                      )}

                                      {user.hasPermission("case.msgs_write") && (
                                        <React.Fragment>
                                          <strong style={{
                                            display: "block",
                                            fontSize: "13px",
                                            borderTop: "1px solid #EEE",
                                            paddingTop: "20px",
                                            margin: "20px 0 10px 0"
                                          }}>{i18n("table_container", "new_message")}</strong>

                                          <TextField ref={msgTitleRef}
                                                label={i18n("table_container", "title")}
                                                placeholder={i18n("table_container", "enter")}
                                                style={{margin: "0 0 10px 0", maxWidth: "300px"}}
                                                validation={value => value.length > 0}/>

                                          <TextView ref={msgTextRef}
                                                label={i18n("table_container", "text")}
                                                placeholder={i18n("table_container", "enter")}
                                                maxLength={750}
                                                validation={value => value.length > 0}
                                                resize="vertical"
                                                fullWidth
                                                minHeight={250}
                                                style={{margin: "0 0 20px 0", maxWidth: "480px"}}/>


                                          <Button onClick={() =>
                                          {
                                            const title = msgTitleRef.current.value;
                                            const text = msgTextRef.current.value;
                                            if (title.length === 0 || text.length === 0)
                                            {
                                              return;
                                            }
                                            onMessage.call(this, entry.caseId, msgTitleRef.current.value, msgTextRef.current.value);
                                          }}>
                                            {i18n("table_container", "save")}
                                          </Button>
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  )}

                                  {entry.signatories.length > 0 && (
                                    <React.Fragment>
                                      <h4 style={{marginBottom: "15px"}}>{i18n("table_container", "signatories")}</h4>
                                      <ul className={"case-signatories"}>
                                        {entry.signatories.map((party) =>
                                        {
                                          const rowSigned = entry.state === "signed" || party.Signed;
                                          return (
                                            <li className={rowSigned ? "signed" : ""} key={party.AssentlyId}>
                                              <span className={"box"}>
                                                {rowSigned && (
                                                  <React.Fragment>
                                                    &#10003;
                                                  </React.Fragment>
                                                )}
                                              </span>

                                              <span className={"name"}>
                                                {party.Name}
                                              </span>

                                              {party.Signed && (
                                                <span className={"date"}>
                                                  ({i18n("table_container", "signed")}: {party.Signed})
                                                </span>
                                              )}
                                            </li>
                                          );
                                        })}
                                      </ul>

                                      {entry.state === "sent" &&
                                        partner.partnerId === 'fair' &&
                                        entry.waiting_for_final_signature &&
                                        user.hasPermission("case.sign_bo") && (
                                          <Button onClick={onAction.bind(this, "sign_bo", entry.caseId, entry.ssn)}>
                                            {i18n("table_container", "mark_as_signed_by_backoffice")}
                                          </Button>
                                        )}
                                    </React.Fragment>
                                  )}

                                  {entry.commission_sharing !== "" && (
                                    <React.Fragment>
                                      <h4 className={"less-margin"}>{i18n("table_container", "commission_distribution")}</h4>
                                      <p style={{marginTop: "0"}}>
                                        {entry.commission_sharing}
                                      </p>
                                    </React.Fragment>
                                  )}

                                  {entry.message_to_bo !== null && entry.message_to_bo !== "" && (
                                    <React.Fragment>
                                      <h4 className={"less-margin"}>{i18n("table_container", "message_to_backoffice")}</h4>
                                      <p style={{marginTop: "0"}}>
                                        {entry.message_to_bo}
                                      </p>
                                    </React.Fragment>
                                  )}

                                  {entry.audit.length > 0 && user.hasPermission("case.audit_log") && (
                                    <React.Fragment>
                                      <h4 className={"less-margin"}>{i18n("table_container", "log")}</h4>

                                      <ul className={"audit"}>
                                        {entry.audit.map((entry, key) =>
                                        {
                                          return (
                                            <li key={key}>
                                              <span
                                                className={"created"}>{entry.CreatedForDisplay}</span>
                                              <span
                                                className={"originator"}>{entry.OriginatorName}</span>
                                              <span className={"action"}>
                                                {entry.Action === "created" && (
                                                  <React.Fragment>
                                                    {i18n("table_container", "case_created")} {entry.caseId}
                                                  </React.Fragment>
                                                )}

                                                {entry.Action === "signed" && (
                                                  <React.Fragment>
                                                    {i18n("table_container", "case_signed")}
                                                  </React.Fragment>
                                                )}

                                                {entry.Action === "sent_for_sign" && (
                                                  <React.Fragment>
                                                    {i18n("table_container", "sent_for_signing")}
                                                  </React.Fragment>
                                                )}

                                                {entry.Action === "finished" && (
                                                  <React.Fragment>
                                                    {i18n("table_container", "marked_as_signed_by_all")}
                                                  </React.Fragment>
                                                )}

                                                {entry.Action === "opened" && (
                                                  <React.Fragment>
                                                    {i18n("table_container", "case_opened")}
                                                  </React.Fragment>
                                                )}

                                                {entry.Action === "reminded" && (
                                                  <React.Fragment>
                                                    {i18n("table_container", "sent_reminder")}
                                                  </React.Fragment>
                                                )}

                                                {entry.Action === "recalled" && (
                                                  <React.Fragment>
                                                    {i18n("table_container", "case_revoked")}
                                                  </React.Fragment>
                                                )}

                                                {entry.Action === "download" && (
                                                  <React.Fragment>
                                                    {i18n("table_container", "downloaded_documentation")}
                                                  </React.Fragment>
                                                )}

                                                {entry.Action === "download_futur" && (
                                                  <React.Fragment>
                                                    {i18n("table_container", "downloaded_documentation_futur")}
                                                  </React.Fragment>
                                                )}

                                                {entry.Action === "processing_set" && (
                                                  <React.Fragment>
                                                    {i18n("table_container", "set_status_in_progress")}
                                                  </React.Fragment>
                                                )}

                                                {entry.Action === "processing_unset" && (
                                                  <React.Fragment>
                                                    {i18n("table_container", "reset_status_in_progress")}
                                                  </React.Fragment>
                                                )}

                                                {entry.Action === "copied" && (
                                                  <React.Fragment>
                                                    {i18n("table_container", "copied_case")}
                                                  </React.Fragment>
                                                )}
                                              </span>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </React.Fragment>
                                  )}
                                </TableCell>
                              </TableRow>
                            )}                  
                          </Fragment>
                        )
                      })
                    }
                  </Fragment>
                )}
              </TableBody>
            </Table>
          </Box>
          <CustomTableFooter
            loaded={loaded}
            type="other"
            totalCases={numCases}
            data={copyList}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage === -1 && numCases > 100 ? 100 : rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleChangePage={handleChangePage}
          />
        </TableContainer>
      </Box>
    </ContentBox>
  );
};

export default OtherTable;