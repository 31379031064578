import React from "react";
import ContentBox from "@components/ContentBox/ContentBox";
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
import DashboardSelector from "./DashboardSelector";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import api from "@helpers/api";
import { getCookie } from "@helpers/cookies";
import Modal from "@components/Modal/Modal";
import OtherTable from "./OtherTable";

/**
 * Övriga Handlingar
 */
export default class DashboardOther extends React.PureComponent
{

	state = _getInitialState.call(this);

	async componentDidMount()
	{
		this._isMounted = true;
		const userCaseList = await _getUserCaseList.call(this);
		this.setState({
			isLoading: false,
			userCaseList: userCaseList.batch,
      totalCases: parseInt(userCaseList.total)
		});
	}

	componentWillUnmount()
	{
		this._isMounted = false;
	}

  async componentDidUpdate(prevProps, prevState)
	{
    if (this.props.caseFilter.other !== prevProps.caseFilter.other) {
      this.setState({
        isLoadingCases: true
      });
      const userCaseList = await _getUserCaseList.call(this);
      this.setState({
        isLoadingCases: false,
        userCaseList: userCaseList.batch,
        totalCases: parseInt(userCaseList.total)
      });
    }
	}

	render()
	{
		const {props, state} = this;
		const {user, advisor, onToggle, partner, faAuth, showHeader} = props;

		// /**
		//  * Filter caseList depending on which tab has been selected (if the selected tab is not "all")
		//  */
		// const caseList = [];
		// for (let i = 0; i < state.userCaseList.length; i++)
		// {
		// 	const userCase = state.userCaseList[i];
		// 	if ((props.caseFilter.other === "all" || userCase.state === props.caseFilter.other) && userCase.state !== "deleted")
		// 	{
		// 		caseList.push(userCase);
		// 	}
		// }

		return (
			<>
				<ContentBox
         style={
          props.type === "customerModal" ? 
            { 
              maxHeight: "70vh",
              margin: 0,
              padding: 0,
              overflow: "auto",
              boxShadow: "none"
            }
          :
            {}
          }
        >
          {showHeader && (
            <h2>
              {i18n("dashboard_selector", "other")}
            </h2>            
          )}

					{state.isLoading && (
						<div style={{padding: "91px 0"}}>
							<ActivityIndicator color="#4cb7d5" busy/>
						</div>
					)}

					{!state.isLoading && (
						<div className="dashboard-cases">
              <OtherTable
                data={state.userCaseList}
                totalCases={state.totalCases}
                isLoadingCases={state.isLoadingCases || false}
                user={user}
                type={"other"}
                partner={partner}
                faAuth={faAuth}
                showHeader={showHeader}
                caseFilter={props.caseFilter} 
                setCaseFilter={props.setCaseFilter}
                getCases={_getUserCaseList.bind(this)}
                onAction={this._onRowAction}
                onMessage={this._onRowMsg}
              />
							{state.isDisabled && (
								<div className="dashboard-cases-disabled">
									<ActivityIndicator color="#4cb7d5" busy/>
								</div>
							)}
						</div>
					)}
				</ContentBox>

				<Modal visible={state.isReminderModalVisible}>
					<p>{i18n("dashboard_sessions", "reminder_to_customer")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideReminderModal}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isRecallModalVisible}>
					<p>{i18n("dashboard_sessions", "case_revoked")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideRecallModal}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isDeleteModalVisible}>
					<p>{i18n("dashboard_sessions", "draft_removed")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideDeleteModal}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isProcessingModalVisible}>
					<p>{i18n("dashboard_sessions", "case_moved_in_progress")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideProcessingModal}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isSignedByBoVisible}>
					<p>{state.signedByBoMessage}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideSignedByBo}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isProcessingUnsetModalVisible}>
					<p>{i18n("dashboard_sessions", "case_moved_sent")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideProcessingUnsetModal}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isMessageModalVisible}>
					<p>{i18n("dashboard_sessions", "message_saved")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideMsgModal}/>
					</Modal.ButtonsContainer>
				</Modal>
			</>
		);
	}

	_onRowMsg = async (caseId, title, text) =>
	{
		const {user} = this.props;

		const msg = await api("/case/message", {
			caseId: caseId,
			partnerId: user.ssn,
			title: title,
			text: text
		});

		this.state.userCaseList.forEach(function (userCase)
		{
			if (userCase.caseId === caseId)
			{
				userCase.messages.unshift(msg);
			}
		});

		this.setState({
			isMessageModalVisible: true,
			userCaseList: this.state.userCaseList
		});
	};

	_onRowAction = async (action, caseId, ssn) =>
	{
		const {user, advisor, cobForm, customerState, setCustomerState} = this.props;

		if (action === 'remind')
		{
			api("/case/reminder", {
				caseId: caseId,
				userId: user.ssn,
				partnerId: advisor.ssn
			});

			this.setState({
				isReminderModalVisible: true
			});
			return;
		}

		if (action === 'sign_bo')
		{
			try
			{
				const result = await api("/case/sign_bo", {
					caseId: caseId,
					userId: user.ssn,
					partnerId: advisor.ssn
				});

				this.state.userCaseList.forEach(function (userCase)
				{
					if (userCase.caseId === caseId)
					{
						userCase.state = 'signed';
						const today = new Date();
						const month = (today.getMonth() + 1);
						const date = today.getFullYear() + '-' + (month < 10 ? "0" + month : month) + '-' + today.getDate();
						const minutes = today.getMinutes();
						const time = today.getHours() + ":" + (minutes < 10 ? "0" + minutes : minutes);
						userCase.signed = date + ' ' + time;
					}
				});

				this.setState({
					isSignedByBoVisible: true,
					userCaseList: this.state.userCaseList,
					signedByBoMessage: result
				});
			}
			catch (error)
			{
				this.setState({
					isSignedByBoVisible: true,
					signedByBoMessage: error.message
				});
			}

			return;
		}

		if (action === 'delete')
		{
			api("/case/delete", {
				caseId: caseId,
				userId: user.ssn,
				partnerId: advisor.ssn
			});

			this.state.userCaseList.forEach(function (userCase)
			{
				if (userCase.caseId === caseId)
				{
					userCase.state = 'deleted';
				}
			});

			this.setState({
				isDeleteModalVisible: true,
				userCaseList: this.state.userCaseList
			});

			return;
		}

		if (action === 'recall')
		{
			api("/case/recall", {
				caseId: caseId,
				userId: user.ssn,
				partnerId: advisor.ssn
			});

			this.state.userCaseList.forEach(function (userCase)
			{
				if (userCase.caseId === caseId)
				{
					userCase.state = 'recalled';
				}
			});

			this.setState({
				isRecallModalVisible: true,
				userCaseList: this.state.userCaseList
			});

			return;
		}

		if (action === 'download')
		{
			const rawDataInBase64 = await api("/case/downloadReceipt", {
				caseId: caseId,
				userId: user.ssn,
				partnerId: advisor.ssn
			}, {}, true);

			if (rawDataInBase64 !== "")
			{
				this.downloadFile(caseId + ".pdf", rawDataInBase64);
			}

			return;
		}

    this.setState({
      isDisabled: true
    }, () =>
    {
      setCustomerState({
        ...customerState,
        selectedCaseId: caseId,
        ssnOrg: ssn.replaceAll("-", ""),
      }, () =>
      {
          cobForm.current._onSubmit();
      });
    });
	};

	_onHideReminderModal = () =>
	{
		this.setState({
			isReminderModalVisible: false
		});
	};

	_onHideRecallModal = () =>
	{
		this.setState({
			isRecallModalVisible: false
		});
	};

	_onHideDeleteModal = () =>
	{
		this.setState({
			isDeleteModalVisible: false
		});
	};

	_onHideProcessingModal = () =>
	{
		this.setState({
			isProcessingModalVisible: false
		});
	};

	_onHideSignedByBo = () =>
	{
		this.setState({
			isSignedByBoVisible: false
		});
	};

	_onHideProcessingUnsetModal = () =>
	{
		this.setState({
			isProcessingUnsetModalVisible: false
		});
	};

	_onHideMsgModal = () =>
	{
		this.setState({
			isMessageModalVisible: false
		});
	};

	downloadFile(filename, base64pdf)
	{
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		//blob = atob(blob);

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const link = document.createElement('a');
		link.href = "data:application/pdf;base64," + base64pdf;
		link.download = filename;
		link.click();
	}
}

function _getInitialState()
{
	return {
		caseFilter: "all",
		isLoading: true,
    isLoadingCases: false,
		isDisabled: false,
		isMounted: false,
		isReminderModalVisible: false,
		isRecallModalVisible: false,
		isDeleteModalVisible: false,
		isProcessingModalVisible: false,
		isProcessingUnsetModalVisible: false,
		isMessageModalVisible: false,
		isSignedByBoVisible: false,
		signedByBoMessage: "",
		userCaseList: []
	};
}

function _getUserCaseList(page, searchString, order, orderBy)
{
	const ssn = this.props.user.ssn;

  // check if we have any options saved..
  const cobOptions = JSON.parse(getCookie("cobOptions")) || null;
  // .. and set rowsPerPage
  const rowsPerPage= cobOptions?.rowsPerPage["other"] || 10;

	return new Promise(async (resolve, reject) =>
	{
		try
		{
			console.log("Loading case list...");
			const cases = await api("/case/list", {
				ssn: ssn,
				mode: "other",
        filter: this.props.caseFilter.other,
        page: page || 0,
        order: order || "desc",
        orderBy: orderBy || "changed",
        searchString: searchString || "",
        limit: rowsPerPage === -1 ? 100 : rowsPerPage
			});

			if (this._isMounted)
			{
				resolve(cases);
			}
		}
		catch (e)
		{
			console.error(e.message);
			reject();
		}
	});
}