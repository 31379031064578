import { useState, useEffect, useRef } from "react";
import i18n from "@helpers/i18n";
import { getCookie } from "@helpers/cookies";

const useOtherTable = props => {
  const { 
    data,
    totalCases,
    user,
    partner,
    faAuth,
    type,
    onShareOpen,
    caseFilter,
    setCaseFilter,
    getCases,
    isLoadingCases,
    onAction,
    onMessage
  } = props;

  const [mounted, setMounted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [copyList, setCopyList] = useState(data);
  const [numCases, setNumCases] = useState(totalCases);
  const [page, setPage] = useState(0);
  const [searchString, setSearchString] = useState('');
  const searchTimeout = useRef(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("changed");

  // check if we have any options saved..
  const cobOptions = JSON.parse(getCookie("cobOptions")) || null;
  // .. and set rowsPerPage setting accordingly
  const [rowsPerPage, setRowsPerPage] = useState(
    cobOptions?.rowsPerPage["other"] || 10
  );

  const tabs = [
    {
      key: "all",
      translationKey: "all"
    },
    {
      key: "sent",
      translationKey: "sent"
    },
    {
      key: "signed",
      translationKey: "signed"
    },
    {
      key: "recalled",
      translationKey: "revoked"
    }
  ];

  let columns = [];
  columns.push(      
    {
      Header: "",
      id: 'expander',
      accessor: "expander",
      width: "2.5%",        
      disablePadding: true,
    },
    {
      Header: `Typ`,
      className: "advisor",
      accessor: "type",
      width: user.role !== "advisor" ? "12.5%" : "15%",
      disableSort: true
    }
  );

  if (user.role !== "advisor") {
    columns.push({
      Header: `${i18n("tables", "advisor")}`,
      className: "advisor",
      accessor: "advisorName",
      width: "12.5%"
    });
  }

  columns.push(   
    {
      Header: `${i18n("tables", "person_orgnr")}`,
      className: "ssn",
      accessor: "ssn",
      width: user.role !== "advisor" ? "10%" : "12.5%",
      disableSort: true
    },
    {
      Header: `${i18n("tables", "name")}`,
      className: "name",
      accessor: "name",
      width: user.role !== "advisor" ? "15%" : "17.5%"
    },
    {
      Header: `${i18n("tables", "updated")}`,
      accessor: "changed",
      className: "changed",
      width: user.role !== "advisor" ? "12.5%" : "15%"
    },
    {
      Header: `${i18n("dashboard_sessions", "table_header_sent")}`,
      accessor: "sent",
      className: "sent",
      width: user.role !== "advisor" ? "12.5%" : "15%",
    },
    {
      Header: `${i18n("dashboard_sessions", "table_header_signed")}`,
      accessor: "signed",
      className: "signed",
      width: user.role !== "advisor" ? "10%" : "12.5%"
    }
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setPage(0);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const requestSearch = searched => {
    setSearchString(searched.toLowerCase());
  };

  const handleEntryClick = caseId => {
    const index = copyList.findIndex(object => {
      return object["caseId"] === caseId;
    });

    let updatedData = [...copyList]; // copy prev state data array
    updatedData[index]["toggled"] = !copyList[index].toggled;

    setCopyList(updatedData);
  };

  const fetchCases = async (page, searchString, order, orderBy) => {
    setLoaded(false);
    const cases = await getCases(page, searchString, order, orderBy);
    setCopyList(cases.batch);
    setNumCases(parseInt(cases.total));
    setLoaded(true);
  }

  // inital load and page/rowsPerPage changes useEffect
  useEffect(() => {   
    // (ideally we would get rid of this but will need reactoring work on the DashboardOther component)
    if (!mounted) {      
      setLoaded(true);
      setMounted(true);
    }

    if (mounted) {
      fetchCases(page, searchString, order, orderBy);
    }
  }, [page, rowsPerPage, order, orderBy])
  
  // Search useEffect
  useEffect(() => {   
    if (mounted) {
      if (searchTimeout.current > 0) clearTimeout(searchTimeout.current);
      searchTimeout.current = setTimeout(() => {
        if (page > 0) setPage(0);
        fetchCases(page, searchString);        
      }, 2000);
    }
  }, [searchString])

  // TAB(data) change useEffect
  useEffect(() => {   
    if (mounted) {      
      setOrder("desc");
      setOrderBy("changed"); // default sort - setOrder/setOrderBy trigger adds double or triple (searchString !== "") spending when changing tabs, optimise later!

      setCopyList(data);
      setNumCases(totalCases);
    }
  }, [data])
  
  // CASES loading useEffect
  useEffect(() => {   
    if (mounted) {
      setLoaded(!isLoadingCases);
    }
  }, [isLoadingCases])



  return {
    user,
    partner,
    type,
    onMessage,
    onShareOpen,
    loaded,
    tabs,
    columns,
    copyList,
    numCases,
    page,
    setPage,
    searchString,
    setSearchString,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    handleRequestSort,
    requestSearch,
    handleEntryClick,
    onAction,
    caseFilter,
    setCaseFilter,
    isLoadingCases
  };
};

export default useOtherTable;