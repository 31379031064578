import React from "react";

import Validator from "@classes/Validator";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import Checkbox from "@components/Checkbox/Checkbox";
import Button from "@components/Button/Button";
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import Modal from "@components/Modal/Modal";
import StackLayout from "@components/layouts/StackLayout/StackLayout";
import TextField from "@components/TextField/TextField";
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
import fulfillment from "./CustomerInformation.fulfillment";
import CountrySelection from "../../../components/CountrySelection/CountrySelection";
import Select from "../../../../../components/Select/Select";
import CompanyReps from "../../CustomerInformation/subcomponents/CompanyReps/CompanyReps";
import {loop} from "../../../../../helpers/utils";
import IdentityDocument from "../../../components/IdentityDocument/IdentityDocument";
import DatePicker from "react-datepicker";
import FileDrop from "../../../../../components/FileDrop/FileDrop";
import {DocsList} from "../../../../../components/DocsList/DocsList";
import LeiCodeField from "../../../components/LeiCode/LeiCodeField";
import LeiCodeFieldFulfillment from "../../../components/LeiCode/LeiCodeField.fulfillment";
import PartnerHiddenFields from "../../../components/PartnerHiddenFields/PartnerHiddenFields";

export default class CustomerInformation extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	setLookupInfo(lookupInfo)
	{
		const {props, state} = this;
		const {customerState} = state;
		const {isCompany} = props.customer;

		const clientInfoPhone = lookupInfo.client.phone && lookupInfo.client.phone !== "-" ? lookupInfo.client.phone : "";
		const clientInfoPhoneAlt = lookupInfo.client.phone_alternative && lookupInfo.client.phone_alternative !== "-" ? lookupInfo.client.phone_alternative : "";

		this.setState({docsFulfilled: props.customer.hasValidIdentityDocument(), lookupInfo: lookupInfo}, () =>
		{
			this.setCustomerState({
				firstName: customerState.firstName || lookupInfo[isCompany ? "companyName" : "firstName"] || "",
				lastName: isCompany ? "" : (customerState.lastName || lookupInfo.lastName || ""),
				street: customerState.street || lookupInfo.street || "",
				zipCode: customerState.zipCode || lookupInfo.zipCode || "",
				city: customerState.city || lookupInfo.city || "",
				email: customerState.email || lookupInfo.email || lookupInfo.client.email || "",
				phoneNumber: customerState.phoneNumber || lookupInfo.phoneNumber || clientInfoPhone || "",
				phoneNumberHome: customerState.phoneNumberHome || lookupInfo.phoneNumberHome || clientInfoPhoneAlt || "",
				phoneNumberAlternative: customerState.phoneNumberAlternative || "",
				leiCodeValid: LeiCodeFieldFulfillment.call(this, LeiCodeField.getViewInitialStateForCustomer(props.customer, lookupInfo, true), null)
			});
		});
	}

	async componentDidMount()
	{
		const {props} = this;
		const {customer, history, setAppState} = props;
		const ssnOrg = customer.getData("customer_social_security_number", null) || customer.getData("customer_ssn", null);

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {disabled: true},
        next: {
          onClick: () => {
            if (this.state.isFulfilled === true) {
              return;
            }

            this.setState({hasFormValidationErrors: true});
            return setAppState({
              showValidationWarning: true
            });
          }
        }
			}
		});

		if (!ssnOrg)
		{
			history.push("/cob/1");
			return;
		}

		if (!customer.lookupInfo)
		{
			try
			{
				await customer.lookupAsync();
				const lookupNotFound = customer.lookupInfo.type === undefined;
				this.setState({isLoading: false, lookupNotFound}, () => this.setLookupInfo(customer.lookupInfo));
			}
			catch (e)
			{
				if (e.message === 'FRAUD_FLAG')
				{
					console.warn("Customer information locked. Case can not be completed.");
					this.setState({isLoading: false, lookupNotFound: true, fraudFlag: true, lookupInfo: null});
				}
				else
				{
					console.warn("Customer information not found. Information must be entered manually.");
					this.setState({isLoading: false, lookupNotFound: true, fraudFlag: false, lookupInfo: null});
				}
			}
		}
		else
		{
			this.setState({isLoading: false}, () => this.setLookupInfo(customer.lookupInfo));
		}
	}

	render()
	{
		const {props, state} = this;
		const {user, customer, onRestartSession} = props;
		const {customerState, lookupNotFound, hasFormValidationErrors} = state;
		const classes = createClassName("Cob-view Cob-view2", {});

		const totalDocsSize = state.docs.reduceRight((a, b) => a + Math.round(b.size / 1024), 0);
		const maxUploadFileSize = Math.round(state.maxUploadFileSize / 1024);

		if (state.isLoading)
		{
			return <ActivityIndicator busy/>;
		}

		let ssnOrg = customerState.ssnOrg;
		if (ssnOrg)
		{
			if (ssnOrg.length === 12)
			{
				ssnOrg = ssnOrg.substring(0, 8) + "-" + ssnOrg.substring(8);
			}
			else if (ssnOrg.length === 10)
			{
				ssnOrg = ssnOrg.substring(0, 6) + "-" + ssnOrg.substring(6);
			}
		}

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>
				<PartnerHiddenFields customer={customer} user={user} />
				<input type="hidden" name="customer_phonenumber_home" value={customerState.phoneNumberHome || "-"}/>
				<input type="hidden" name="customer_phonenumber_cell" value={customerState.phoneNumber || "-"}/>
				<input type="hidden" name="customer_phonenumber_alt" value={customerState.phoneNumberAlternative || "-"}/>
				<input type="hidden" name="customer_classification" value="Icke-professionell kund"/>
				<input type="hidden" name="radio_customer_type" value={customer.isCompany ? "company" : "person"}/>

				<Modal visible={state.fraudFlag} status="danger" title="Personnummer låst">
					<React.Fragment>
						<p>
							Kundens personnummer är låst via en extern ID-spärr. Vänligen kontakta backoffice.
						</p>

						<Modal.ButtonsContainer>
							<Modal.Button label="Avsluta rådgivning" appearance="primary" onClick={onRestartSession} filled/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>

				<ContentBox>
					<h2>{i18n("cob", "customer_information")}</h2>

					<div className="Cob-view2-fields">
						<h3>Mötesinformation</h3>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<strong style={{marginBottom: "8px"}}>Datum för rådgivningen</strong>
                				{/* Might need extra work (merge changed component from TextField to DatePicker) */}
								<DatePicker
									placeholderText="Välj datum"
									calendarStartDay={1}
									selected={customerState.date_object}
									dateFormat="yyyy-MM-dd"
									maxDate={new Date()}
									className={customerState.date.length > 0 && customerState.date !== "1970-01-01" ? "datepicker-valid" : hasFormValidationErrors ? "datepicker-invalid" : ""}
									onChange={date => this.setCustomerState({
										date_object: date,
										date: (new Intl.DateTimeFormat("sv-SE", {
											year: "numeric",
											month: "2-digit",
											day: "2-digit"
										}).format(date))
									})}
                />
                {(customerState.date === "1970-01-01" || customerState.date.length < 1) && hasFormValidationErrors ?
								      <DatePickerInvalidStatus icon="error"/>
								    : null}
								<input type={"hidden"} name={"date"} value={customerState.date}/>
							</div>
							<div>
								<strong style={{marginBottom: "8px"}}>Rådgivningens utförande*</strong>
								<Select
									label={"Välj mötesform"}
									selectedIndex={customerState.meetingFormatIndex}
									options={[
										{label: "Fysiskt möte", value: "Fysiskt möte"},
										{label: "Digitalt möte", value: "Digitalt möte"},
										{label: "Telefonmöte", value: "Telefonmöte"}
									]}
									onChange={(option, meetingFormatIndex) => this.setCustomerState({
										meetingFormat: option.value,
										meetingFormatIndex
									})}
                  highlightErrors={hasFormValidationErrors}
								/>

								<input type="hidden" name="radio_meeting_type" value={customerState.meetingFormat}/>
							</div>
						</StackLayout>

						<div className="h-line spacing"/>

						<h3>{customer.isCompany ? "Företagets uppgifter" : "Grunduppgifter"}</h3>

						{lookupNotFound && !customer.isCompany && (
							<React.Fragment>
								<StackLayout orientation="vertical" alignItems="start">
									<Checkbox
										name="checkbox_customer_foreigner"
										label={i18n("cob", "lives_overseas_foreign_citizen")}
										checked={customerState.isForeigner}
										onChange={isForeigner => this.setCustomerState({isForeigner})}
                    highlightErrors={hasFormValidationErrors}
									/>

									{customerState.isForeigner && (
										<Checkbox
											style={{marginTop: 10}}
											label="Är bosatt i EU"
											checked={customerState.isNonEuPerson}
											onChange={
												isNonEuPerson => this.setState({
													errorMessage: "För hantering av personer bosatta utanför EU, var god kontakta backoffice."
												}, () => this.setCustomerState({isNonEuPerson}))
											}
                      highlightErrors={hasFormValidationErrors}
										/>
									)}
								</StackLayout>
							</React.Fragment>
						)}

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>

							<div style={{alignSelf: "flex-start"}}>
								<TextField
									name="customer_firstname"
									value={customerState.firstName}
									autocomplete="nope"
									label={i18n("general", customer.isCompany ? "organization_name" : "first_name")}
									validation={lookupNotFound ? value => value.length > 0 : undefined}
									onChange={lookupNotFound ? ({target}) => this.setCustomerState({firstName: target.value}) : undefined}
									readOnly={!lookupNotFound}
									// required={lookupNotFound}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>

							{!customer.isCompany && (
								<div style={{alignSelf: "flex-start"}}>
									<TextField
										name="customer_lastname"
										value={customerState.lastName}
										autocomplete="nope"
										label={i18n("general", "last_name")}
										validation={lookupNotFound ? value => value.length > 0 : undefined}
										onChange={lookupNotFound ? ({target}) => this.setCustomerState({lastName: target.value}) : undefined}
										// required={lookupNotFound}
										highlightErrors={hasFormValidationErrors}
									/>
								</div>
							)}

							<div style={{alignSelf: "flex-start"}}>
								<TextField
									name="customer_ssn"
									autocomplete="nope"
									value={customerState.ssnOrg}
									label={i18n("general", customer.isCompany ? "organization_number" : "social_security_number")}
									readOnly
								/>
							</div>

							{customer.isCompany && (
								<div style={{alignSelf: "flex-start"}}>
									<LeiCodeField customer={customer}
												  lookupInfo={state.lookupInfo}
												  onUpdated={this._onLeiUpdated}
												  highlightErrors={hasFormValidationErrors}
												  required={true}
									/>
								</div>
							)}
						</StackLayout>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<TextField
									name="customer_address_street"
									value={customerState.street}
									label={i18n("general", "street_address")}
									autocomplete="nope"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({street: target.value})}
									// required={true}
                  highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name="customer_address_zipcode"
									value={customerState.zipCode}
									label={i18n("general", "zip_code")}
									autocomplete="nope"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({zipCode: target.value})}
									// required={true}
                  highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name="customer_address_city"
									value={customerState.city}
									label={i18n("general", "city")}
									autocomplete="nope"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({city: target.value})}
									// required={true}
                  highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<CountrySelection
                  label={"Land"}
                  field={"customer_address_country"}
                  selected={customerState.country}
                  mandatory={true}
                  onChange={(value) => this.setCustomerState({country: value.label})}
                  highlightErrors={hasFormValidationErrors}
                />
							</div>
						</StackLayout>

						<div className="h-line spacing"/>

						{!customer.isCompany && (
							<React.Fragment>
								<h3>Kontaktuppgifter</h3>

								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div>
										<TextField
											name="customer_phonenumber_cell_input"
											value={customerState.phoneNumber === "-" ? "" : customerState.phoneNumber}
											label="Telefon"
											autocomplete="nope"
                      validation={Validator.validatePhoneNum}
											placeholder={"+46 XX XXXXXX"}
											onChange={({target}) => this.setCustomerState({phoneNumber: target.value === "" ? "-" : target.value})}
                      highlightErrors={hasFormValidationErrors}
										/>
									</div>

									<div>
										<TextField
											name="customer_phonenumber_home_input"
											value={customerState.phoneNumberHome === "-" ? "" : customerState.phoneNumberHome}
											label={"Telefon (hem)"}
											autocomplete="nope"
                      validation={customerState.phoneNumberHome && customerState.phoneNumberHome !== "-" ? Validator.validatePhoneNum : false}
											placeholder={"+46 XX XXXXXX"}
											onChange={({target}) => this.setCustomerState({phoneNumberHome: target.value === "" ? "-" : target.value})}
                      highlightErrors={hasFormValidationErrors}
										/>
									</div>
									<div>
										<TextField
											name="customer_phonenumber_alt_input"
											value={customerState.phoneNumberAlternative === "-" ? "" : customerState.phoneNumberAlternative}
											label="Telefon (alternativ)"
											autocomplete="nope"
                      validation={customerState.phoneNumberAlternative && customerState.phoneNumberAlternative !== "-" ? Validator.validatePhoneNum : false}
											placeholder={"+46 XX XXXXXX"}
											onChange={({target}) => this.setCustomerState({phoneNumberAlternative: target.value === "" ? "-" : target.value})}
                      highlightErrors={hasFormValidationErrors}
										/>
									</div>

									<div>
										<TextField
											type="email"
											name="customer_email"
											value={customerState.email}
											autocomplete="nope"
											label={i18n("general", "email_address")}
											validation={Validator.validateEmail}
											onChange={({target}) => this.setCustomerState({email: target.value})}
											// required={true}
                      highlightErrors={hasFormValidationErrors}
										/>
									</div>
								</StackLayout>

								<div className="h-line spacing"/>
							</React.Fragment>
						)}

						{customer.isCompany && (
							<React.Fragment>

								<CompanyReps
									customer={customer}
									reps={customerState.companyReps}
									onRepChange={this._onCompanyRepChange}
									activeAmount={customerState.companyRepsActiveAmount}
									limitedUbo={true}
									withTaxCountry={true}
									fullCountryNameAsValue={true}
									simplified={true}
									requireIdUpload={true}
                  highlightErrors={hasFormValidationErrors}
								/>

								{!this._hasAtLeastOneSignatory(customerState.companyReps) && (
									<div style={{
										padding: "20px",
										background: "#FFECEC",
										borderRadius: "3px",
										maxWidth: "500px",
										margin: "0 0 20px 0"
									}}>
										Åtminstone en firmatecknare eller fullmaktshavare måste väljas.
									</div>
								)}

								{customerState.companyRepsActiveAmount <= 6 && (
									<StackLayout fullWidth>
										<Button
											size="small"
											label={i18n("general", "remove_last")}
											appearance={customerState.companyRepsActiveAmount <= 1 ? "medium" : "danger"}
											disabled={customerState.companyRepsActiveAmount <= 1}
											onClick={() => this.setCustomerState({companyRepsActiveAmount: customerState.companyRepsActiveAmount - 1})}
										/>
										<Button
											size="small"
											label={i18n("general", "add")}
											appearance={customerState.companyRepsActiveAmount >= 6 ? "medium" : "primary"}
											disabled={customerState.companyRepsActiveAmount >= 6}
											onClick={() => this.setCustomerState({companyRepsActiveAmount: customerState.companyRepsActiveAmount + 1})}
											filled
										/>
									</StackLayout>
								)}

								{this._hasAtLeastOnePoaRep(customerState.companyReps) && (
									<React.Fragment>
										<div className="h-line spacing" style={{marginTop: "30px"}}/>
										<h4 className={"margin-bottom"}>Ladda upp kopia på företagsföreträdares fullmakter</h4>

										<FileDrop
											filterKey={"rep-poas"}
											dropText="Dra och släpp dokument här"
											busyText="Laddar in dokument..."
											accept="image/png, image/jpeg, application/pdf"
											onDrop={this._onDocDrop}
											style={{minHeight: "0", paddingTop: "20px", paddingBottom: "30px"}}
										/>

										<DocsList
											filterKey={"rep-poas"}
											items={state.docs}
											totalSize={totalDocsSize}
											maxSize={maxUploadFileSize}
											onDocRemove={this._onDocRemove}
											alignBottom={true}
											// required={true}
										/>
									</React.Fragment>
								)}

								<div className="h-line spacing" style={{marginTop: "30px"}}/>

								<IdentityDocument
									customer={customer}
									updatedDocument={this._onUpdatedIdentity}
									companyMode={true}
									hasValidIdentityDocument={customer.hasValidIdentityDocument()}
									identityDocumentAdded={customer.identityDocumentAdded}
								/>
							</React.Fragment>
						)}
					</div>
				</ContentBox>

				<input type="hidden" name="customer_social_security_number" value={ssnOrg.replace("-", "")}/>
				<input type="hidden" name="customer_ssn" value={ssnOrg}/>
			</div>
		);
	}

	_onUpdatedIdentity = (valid) =>
	{
		const newState = {...this.state};
		newState.docsFulfilled = valid;
		newState.isFulfilled = this.isFulfilled(newState);
		this.setState(newState, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
		});
	};

	_hasAtLeastOneSignatory(companyReps)
	{
		for (let i = 0; i < this.state.customerState.companyRepsActiveAmount; i++)
		{
			const rep = companyReps[i];
			if (rep.types.length > 0 && (rep.types.includes("signatory") || rep.types.includes("poa")))
			{
				return true;
			}
		}
		return false;
	}

	_hasAtLeastOnePoaRep(companyReps)
	{
		for (let i = 0; i < this.state.customerState.companyRepsActiveAmount; i++)
		{
			const rep = companyReps[i];
			if (rep.types.length > 0 && rep.types.includes("poa"))
			{
				return true;
			}
		}
		return false;
	}

	// Internal methods
	_onCompanyRepChange = (rep, index) =>
	{
		const companyReps = this.state.customerState.companyReps.map((r, i) => i === index ? rep : r);
		this.setCustomerState({companyReps});
	};

	_onDocDrop = (documents) =>
	{
		const {props, state} = this;
		const {customer} = props;
		const docs = [...state.docs];
		const docNames = [...state.docNames];


		for (let i = 0, n = documents.length; i < n; i++)
		{
			const doc = documents[i];

			// Prevent duplicates
			if (docNames.indexOf(doc.name) !== -1)
			{
				console.log("Skipped duplicate document " + doc.name);
				continue;
			}

			docs.push({name: doc.name, size: doc.size, filterKey: doc.filterKey});
			docNames.push(doc.name);

			customer.addDocument({
				name: doc.name,
				size: doc.size,
				data: doc.data,
				filterKey: doc.filterKey
			});
		}

		this.setState({docs, docNames, isFulfilled: this.isFulfilled({docs, docNames})}, () =>
		{
			props.setAppState({viewFulfilled: this.state.isFulfilled});
		});
	};

	_onDocRemove = (item) =>
	{
		const {props, state} = this;
		const {customer} = props;

		const docs = [...state.docs].filter((d) =>
		{
			return d.name !== item.name;
		});

		const docNames = [...state.docNames].filter((d) =>
		{
			return d !== item.name;
		});

		customer.removeDocumentWithName(item.name);
		this.setState({docs, docNames, isFulfilled: this.isFulfilled({docs, docNames})}, () =>
		{
			props.setAppState({viewFulfilled: this.state.isFulfilled});
		});
	}

	_onLeiUpdated = (fulfilled) => {
		this.setCustomerState({leiCodeValid: fulfilled});
	}
}

// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, viewNo, hasFormValidationErrors} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.customerState.checkRepDocs = true;
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	let initialState = {
		isFulfilled: false,
		isLoading: true,

		lookupInfo: null,
		lookupNotFound: false,
		fraudFlag: false,
		errorMessage: null,

		docsFulfilled: customer.hasValidIdentityDocument(),
		docs: customer.documents.map(doc => ({name: doc.name, size: doc.size, filterKey: doc.filterKey})),
		docNames: customer.documents.map(doc => doc.name),
		maxUploadFileSize: customer.maxUploadFileSize,

		customerState: {
			isCompany: customer.isCompany,
			checkRepDocs: false,
			date_object: null,
			date: customer.getData("date"),
			ssnOrg: customer.getData("customer_social_security_number") || customer.getData("customer_ssn"),
			firstName: customer.getData("customer_firstname"),
			lastName: customer.getData("customer_lastname"),
			street: customer.getData("customer_address_street"),
			zipCode: customer.getData("customer_address_zipcode"),
			city: customer.getData("customer_address_city"),
			country: customer.getData("customer_address_country") || "Sverige",
			phoneNumber: customer.getData("customer_phonenumber_cell"),
			phoneNumberHome: customer.getData("customer_phonenumber_home"),
			phoneNumberAlternative: customer.getData("customer_phonenumber_alt"),
			email: customer.getData("customer_email"),

			leiCodeValid: LeiCodeFieldFulfillment.call(this, LeiCodeField.getViewInitialStateForCustomer(customer, null, true), null),

			companyReps: customer.companyReps,
			companyRepsActiveAmount: Math.max(customer.companyReps.reduce((a, b) => a + (b.firstName ? 1 : 0), 0), 1),

			meetingFormat: customer.getData("radio_meeting_type", null),

			meetingFormatIndex: (
				customer.getData("radio_meeting_type") === "Fysiskt möte" ? 0 :
					customer.getData("radio_meeting_type") === "Digitalt möte" ? 1 :
						customer.getData("radio_meeting_type") === "Telefonmöte" ? 2 :
							-1)
		}
	};

	if (initialState.customerState.date.length > 0)
	{
		initialState.customerState.date_object = Date.parse(initialState.customerState.date);
	}

	return initialState;
}

function _getFields()
{
	let fields = [
		"customer_social_security_number",
		"customer_ssn",
		"customer_firstname",
		"customer_lastname",
		"customer_address_street",
		"customer_address_zipcode",
		"customer_address_city",
		"customer_address_country",
		"customer_address_country_code",
		"customer_citizenship",
		"customer_phonenumber_home",
		"customer_phonenumber_cell",
		"customer_phonenumber_alt",
		"customer_email",

		"account_no_depa",
		"account_no_isk",
		"account_no_kf",
		"account_no_tp",

		// Get company reps
		...(() =>
		{
			const returnValue = [];
			loop(6, i =>
			{
				returnValue.push("checkbox_signatory_" + i);
				returnValue.push("checkbox_ubo_" + i);
				returnValue.push("checkbox_poa_" + i);
				returnValue.push("checkbox_view_" + i);
				returnValue.push("checkbox_mng_" + i);
				returnValue.push("customer_representative_role_" + i);
				returnValue.push("company_representative_role_" + i);
				returnValue.push("company_representative_firstname_" + i);
				returnValue.push("company_representative_lastname_" + i);
				returnValue.push("company_representative_social_security_number_" + i);
				returnValue.push("company_representative_sec_num_" + i);
				returnValue.push("company_representative_percentage_" + i);
				returnValue.push("company_representative_email_" + i);
				returnValue.push("company_representative_phone_" + i);
				returnValue.push("company_representative_citizenship_" + i);
				returnValue.push("company_representative_tax_country_" + i);
				returnValue.push("customer_representative_ssn_" + i);
			});
			return returnValue;
		})(),

		// POAS
		...(() => {
			const returnValue = [];
			loop(6, i => {
				returnValue.push("customer_firstname_poa" + i);
				returnValue.push("customer_lastname_poa" + i);
				returnValue.push("customer_social_security_number_poa" + i);
				returnValue.push("customer_ownershipproc_poa" + i);
			});
			return returnValue;
		})()
	];

	fields = fields.concat(LeiCodeField.getFields());

	return fields;
}

// PRIVATE COMPONENTS
function DatePickerInvalidStatus({icon, requiredSymbol}) {
	return (
		<div className="DatePickerInvalid-status">
			<div className={createClassName("material-icons", {
				"icon": !!icon,
				"required-symbol": requiredSymbol === true,
			})}>{requiredSymbol === true ? "*" : icon}</div>
		</div>
	);
}