import React, {Fragment} from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";

import { getComparator, tableHeightPerTotalPages } from "@helpers/muiTable";

import ContentBox from "@components/ContentBox/ContentBox";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import CustomTableFooter from "@cobComponents/CustomTableFooter";
import searchIcon from "@assets/img/search_icon.svg";
import TableExpanderIcon from "@components//TableExpanderIcon";

import useKycTable from "./useKycTable";
import DashboardTabs from "../DashboardTabs";
import EnhancedTableHead from "./EnhancedTableHead";

const KycTable = props => {
  const {
    loaded,
    tabs,
    columns,
    copyList,
    numCases,
    page,
    setPage,
    searchString,
    setSearchString,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    handleRequestSort,
    requestSearch,
    handleEntryClick,    
    onAction,
    caseFilter,
    setCaseFilter
  } = useKycTable(props);

  return (
    <ContentBox
      style={{
        display: "inline",
        maxHeight: "70vh",
        boxShadow: "none",
        margin: 0,
        padding: 0
      }}
    >
      <Box 
        sx={{
          width: "100%",
          alignItems: "flex-start"
        }}
      >          
        <Box
          sx={{
            display: "flex",
            flexDirection: {xs: "column", md: "row"},
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}
        >
          <DashboardTabs 
            type="kyc" 
            translationGroup="dashboard_kyc"
            tabs={tabs}
            fetchingData={!loaded}
            setPage={setPage}
            setSearchString={setSearchString}
            caseFilter={caseFilter}
            setCaseFilter={setCaseFilter}
          />
          <TextField
            size="small"
            placeholder="Sök..."
            type="search"
            onInput={e => requestSearch(e.target.value)}
            InputProps={{
              value: searchString,
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      paddingBottom: 0.25
                    }}
                    alt="logo"
                    src={searchIcon}
                  />
                </InputAdornment>
              ),
              sx: {
                px: 3,
                fontSize: "13px",
                borderRadius: "20px",
                width: "100%",
              }
            }}
            sx={{ 
              width: {xs: "100%", md: "30%"},
            }}
          />
        </Box>
        <TableContainer component={Box} sx={{ overflowY: "hidden"}}>
          <Box
            sx={{
              display: "block",
              width: "100%",
              minHeight: tableHeightPerTotalPages[rowsPerPage] || "unset",
              border: 0,
              overflow: "auto",
              overflowY: "hidden"
            }}
          >
            <Table aria-label="Kyc Table"
              className="mui-table"
              sx={{
                minWidth: 700,
                mt: 1,
                border: 0,
                tableLayout: "fixed"
              }}>
              <EnhancedTableHead
                columns={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>                
                {!loaded ? (
                  <TableRow
                    sx={{
                      height: "100%",
                      "&:last-child td": { border: 0 }
                    }}
                  >
                    <TableCell colSpan={columns.length}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: tableHeightPerTotalPages[rowsPerPage]*0.7
                        }}
                      >
                        <ActivityIndicator color="#4cb7d5"  busy/>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <Fragment>
                    {copyList && copyList.sort(getComparator(order, orderBy))
                    .map((entry, key) => (
                    <Fragment key={key}>
                      <TableRow
                        className={caseFilter.kyc}
                        sx={{
                          height: 40,
                          cursor: entry.kyc_needs_check === "1"
                            ? "pointer"
                            : "auto",
                          "&:last-child td": { border: 0 }
                        }}
                        onClick={() =>
                          entry.kyc_needs_check === "1" &&
                          handleEntryClick(entry.id)
                        }
                      >
                        <TableCell 
                          sx={{
                            pl: {xs: 0.5, md: 1},
                            pr: 0,
                            py: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          {entry.kyc_needs_check === "1" && (
                            <TableExpanderIcon toggled={entry?.toggled}/>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            px: 0,
                            py: 0.5,
                            borderBottom: "none",
                            color: "secondary.lighter"
                          }}
                        >
                          <Typography variant="tableCell">
                            {entry.id}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            px: 0,
                            py: 0.5,
                            borderBottom: "none",
                            color: "secondary.lighter",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          <Typography variant="tableCell">
                            {entry.name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            px: 0,
                            pr: 1,
                            py: 0.5,
                            borderBottom: "none",
                            color: "secondary.lighter",
                            textAlign: "right"
                          }}
                        >
                          <Typography variant="tableCell">
                            {entry.kyc_date || "-"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {entry?.toggled && (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            sx={{
                              pl: "2.5%"
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: { xs: "column", sm: "row" },
                                width: "100%"
                              }}
                            >
                              <Button 
                                onClick={onAction.bind(this, "download", entry.id)}
                              >
                                Ladda ner ifylld KYC
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}                  
                    </Fragment>
                  ))}
                  </Fragment>
                )}
              </TableBody>
            </Table>
          </Box>
          <CustomTableFooter
            loaded={loaded}
            type="kyc"
            totalCases={numCases}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage === -1 && numCases > 100 ? 100 : rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleChangePage={handleChangePage}
          />
        </TableContainer>
      </Box>
    </ContentBox>
  );
};

export default KycTable;