import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";

import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import TextView from "@components/TextView/TextView";
import Card from "@cobComponents/Card";
import Button from "@components/Button/Button";

import i18n from "@helpers/i18n";

const ExpandableRow = props => {
  const {
    colSpan,
    entry,
    user,
    partner,
    type,
    onAction,
    msgTitleRef,
    msgTextRef,
    onShareOpen,
    onMessage
  } = props;

  const cenas = true;

  return (
    <TableRow    
      sx={{
        borderBottom: "1px solid #F4F6F8"
      }}
    >
      <TableCell
        colSpan={colSpan}
        sx={{
          pl: "2.5%",
          borderBottom: "none",
        }}
      >
        {entry?.loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "middle",
              alignItems: "middle"
            }}
          >
            <ActivityIndicator color="#4cb7d5" busy/>
          </Box>
        ) :
        (
          
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                alignItems: "flex-start",
                width: "100%"
              }}
            >
              {type === "main" && entry.state === "draft" && (user.hasPermission("case.create") || user.ssn === entry.ssn.replaceAll("-", "")) && (
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: "fit-content"
                  }}
                >
                  <Button
                    className="expandable-menu-button"
                    appearance="secondary"
                    label={i18n("table_container", "continue_consultation")}
                    onClick={onAction.bind(this, "continue", entry.caseId, entry.ssn)}
                    filled
                  />
                  {user.role !== "assistant" && !entry.is_shared_case && (
                    <Button
                      className="expandable-menu-button"
                      appearance="secondary"
                      label={i18n("table_container", "remove_draft")}
                      onClick={onAction.bind(this, "delete", entry.caseId, entry.ssn)}
                      filled
                    />
                  )}
                </Box>
              )}

              {(entry.state === "sent" || entry.state === "signed" || entry.state === "recalled") && (
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: "fit-content"
                  }}
                >
                  {type === "main" && (user.hasPermission("case.create") || user.ssn === entry.ssn.replaceAll("-", "")) && (
                    <Button
                      className="expandable-menu-button"
                      appearance="secondary"
                      label={i18n("table_container", "copy_consultation")}
                      onClick={onAction.bind(this, "copy", entry.caseId, entry.ssn)}
                      filled
                    />
                  )}

                  {entry.state === "sent" && (
                    <Button
                      className="expandable-menu-button"
                      appearance="secondary"
                      label={i18n("table_container", "send_reminder")}
                      onClick={onAction.bind(this, "remind", entry.caseId, entry.ssn)}
                      filled
                    />
                  )}

                  {user.role !== "assistant" && entry.state === "sent" && (
                    <Button
                      className="expandable-menu-button"
                      appearance="secondary"
                      label={i18n("table_container", "revoke")}
                      onClick={onAction.bind(this, "recall", entry.caseId, entry.ssn)}
                      filled
                    />
                  )}

                  {type === "main" && entry.state === "sent" && user.hasPermission("case.change_state") && (
                    <Button
                      className="expandable-menu-button"
                      appearance="secondary"
                      label={i18n("table_container", "in_progress")}
                      onClick={onAction.bind(this, "processing", entry.caseId, entry.ssn)}
                      filled
                    />
                  )}

                  {entry.state === "signed" && (user.hasPermission("case.download") || user.ssn === entry.ssn.replaceAll("-", "")) && (
                    <Button
                      className="expandable-menu-button"
                      appearance="secondary"
                      label={`${i18n("table_container", "download_documentation")} (PDF)`}
                      onClick={onAction.bind(this, "download", entry.caseId, entry.ssn)}
                      filled
                    />
                  )}
                </Box>
              )}

              {type === "main" && entry.state === "processing" && user.hasPermission("case.change_state") && (
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: "fit-content"
                  }}
                >
                  <Button
                    className="expandable-menu-button"
                    appearance="secondary"
                    label={i18n("table_container", "reset_to_sent")}
                    onClick={onAction.bind(this, "unprocessing", entry.caseId, entry.ssn)}
                    filled
                  />
                  <Button
                    className="expandable-menu-button"
                    appearance="secondary"
                    label={i18n("table_container", "revoke")}
                    onClick={onAction.bind(this, "recall", entry.caseId, entry.ssn)}
                    filled
                  />                    
                </Box>
              )}

              {/* {user.role !== "assistant" && !entry.is_shared_case && onShareOpen !== undefined && (
                <Button onClick={onShareOpen.bind(this, entry.caseId)}>
                  {i18n("table_container", "sharing_action")}
                </Button>
              )} */}

              {entry.signatories.length > 0 && (
                <Card 
                  title={i18n("table_container", "signatories")}
                  sxStyle={{
                    marginTop: "25px",
                    minWidth: "unset"
                  }}
                >
                  <Box
                    sx={{
                      p:2
                    }}
                  >
                    <ul className={"case-signatories"}>
                      {entry.signatories.map((party, key) =>
                      {
                        const rowSigned = entry.state === "signed" || party.Signed;
                        return (
                          <li className={rowSigned ? "signed" : ""} key={party.AssentlyId}>
                            <span className={"box"}>
                              {rowSigned && (
                                <React.Fragment>
                                  &#10003;
                                </React.Fragment>
                              )}
                            </span>

                            <span className={"name"}>
                              {party.Name}
                            </span>

                            {party.Signed && (
                              <span className={"date"}>
                                ({i18n("table_container", "signed")}: {party.Signed})
                              </span>
                            )}
                          </li>
                        );
                      })}
                    </ul>

                    {entry.state === "sent" &&
                      partner.partnerId === 'fair' &&
                      entry.waiting_for_final_signature &&
                      user.hasPermission("case.sign_bo") && (
                        <Button 
                          className="expandable-menu-button"
                          appearance="secondary"
                          label={i18n("table_container", "mark_as_signed_by_backoffice")}
                          onClick={onAction.bind(this, "sign_bo", entry.caseId, entry.ssn)}
                          filled
                        />
                      )}
                  </Box>
                </Card>
              )}

              {entry.commission_sharing !== "" && (
                <Card
                  title={i18n("table_container", "commission_distribution")}
                  sxStyle={{
                    marginTop: "25px",
                    minWidth: "unset"
                  }}
                >
                  <Box
                    sx={{
                      p:2
                    }}
                  >
                    <p style={{marginTop: "0"}}>
                      {entry.commission_sharing}
                    </p>
                  </Box>
                </Card>
              )}

              {entry.audit.length > 0 && user.hasPermission("case.audit_log") && (
                <React.Fragment>
                  <h4 className={"less-margin"}>{i18n("table_container", "log")}</h4>

                  <ul className={"audit"}>
                    {entry.audit.map((entry, key) =>
                    {
                      return (
                        <li key={key}>
                          <span
                            className={"created"}>{entry.CreatedForDisplay}</span>
                          <span
                            className={"originator"}>{entry.OriginatorName}</span>
                          <span className={"action"}>
                            {entry.Action === "created" && (
                              <React.Fragment>
                                {i18n("table_container", "case_created")} {entry.caseId}
                              </React.Fragment>
                            )}

                            {entry.Action === "signed" && (
                              <React.Fragment>
                                {i18n("table_container", "case_signed")}
                              </React.Fragment>
                            )}

                            {entry.Action === "sent_for_sign" && (
                              <React.Fragment>
                                {i18n("table_container", "sent_for_signing")}
                              </React.Fragment>
                            )}

                            {entry.Action === "finished" && (
                              <React.Fragment>
                                {i18n("table_container", "marked_as_signed_by_all")}
                              </React.Fragment>
                            )}

                            {entry.Action === "opened" && (
                              <React.Fragment>
                                {i18n("table_container", "case_opened")}
                              </React.Fragment>
                            )}

                            {entry.Action === "reminded" && (
                              <React.Fragment>
                                {i18n("table_container", "sent_reminder")}
                              </React.Fragment>
                            )}

                            {entry.Action === "recalled" && (
                              <React.Fragment>
                                {i18n("table_container", "case_revoked")}
                              </React.Fragment>
                            )}

                            {entry.Action === "download" && (
                              <React.Fragment>
                                {i18n("table_container", "downloaded_documentation")}
                              </React.Fragment>
                            )}

                            {entry.Action === "download_futur" && (
                              <React.Fragment>
                                {i18n("table_container", "downloaded_documentation_futur")}
                              </React.Fragment>
                            )}

                            {entry.Action === "processing_set" && (
                              <React.Fragment>
                                {i18n("table_container", "set_status_in_progress")}
                              </React.Fragment>
                            )}

                            {entry.Action === "processing_unset" && (
                              <React.Fragment>
                                {i18n("table_container", "reset_status_in_progress")}
                              </React.Fragment>
                            )}

                            {entry.Action === "copied" && (
                              <React.Fragment>
                                {i18n("table_container", "copied_case")}
                              </React.Fragment>
                            )}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </React.Fragment>
              )}
              {entry.message_to_bo !== null && entry.message_to_bo !== "" && (
                <Card
                  title={i18n("table_container", "message_to_backoffice")}
                  sxStyle={{
                    marginTop: "25px",
                    minWidth: "unset"
                  }}
                >
                  <Box
                    sx={{
                      p:2
                    }}
                  >
                    <p style={{marginTop: "0"}}>
                      {entry.message_to_bo}
                    </p>
                  </Box>
                </Card>
              )}
            </Box>
            {(entry.state === "processing" || entry.state === "sent") && (
              <React.Fragment>
                {(user.hasPermission("case.msgs_write") || entry.messages.length > 0) && (
                  <h4 style={{marginBottom: "15px"}}>
                    {i18n("table_container", "messages_from_backoffice")}
                  </h4>
                )}
                {entry.messages.length > 0 && (
                  <ul className={"messages"}>
                    {entry.messages.map((msg, key) =>
                    {
                      return (
                        <li key={key}>
                          <div>
                            <span className="msg-created">
                              {msg.Created}
                            </span>
                            <span className="msg-title">
                              {msg.Subject}
                            </span>
                          </div>
                          <p>
                            {msg.Message}

                            <span className={"msg-originator"}>
                              - {msg.OriginatorName}
                            </span>
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                )}

                {entry.messages.length === 0 && (
                  <p>
                    <em>{i18n("table_container", "no_case_messages")}</em>
                  </p>
                )}

                {user.hasPermission("case.msgs_write") && (
                  <React.Fragment>
                    <strong style={{
                      display: "block",
                      fontSize: "13px",
                      borderTop: "1px solid #EEE",
                      paddingTop: "20px",
                      margin: "20px 0 10px 0"
                    }}>{i18n("table_container", "new_message")}</strong>

                    <TextField ref={msgTitleRef}
                          label={i18n("table_container", "title")}
                          placeholder={i18n("table_container", "enter")}
                          style={{margin: "0 0 10px 0", maxWidth: "300px"}}
                          validation={value => value.length > 0}/>

                    <TextView ref={msgTextRef}
                          label={i18n("table_container", "text")}
                          placeholder={i18n("table_container", "enter")}
                          maxLength={750}
                          validation={value => value.length > 0}
                          resize="vertical"
                          fullWidth
                          minHeight={250}
                          style={{margin: "0 0 20px 0", maxWidth: "480px"}}/>


                    <Button 
                      onClick={() =>
                      {
                        const title = msgTitleRef.current.value;
                        const text = msgTextRef.current.value;
                        if (title.length === 0 || text.length === 0)
                        {
                          return;
                        }
                        onMessage.call(this, entry.caseId, msgTitleRef.current.value, msgTextRef.current.value);
                      }}
                    >
                      {i18n("table_container", "save")}
                    </Button>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ExpandableRow;